<template>
<div class="list-item" :class="{'last-item': isLast}">
    <div class="box_wrap">
        <div class="flex_h_between_center">
            <w-logo :token0="(item.token0|| '')" :token1="(item.token1|| '').toLowerCase()" v-if="!item.singleStake"></w-logo>
            <w-image-icon :imgName="item.token0" v-else></w-image-icon>
            <div class="flex_v">
              <div class="flex_h_center_center">
                <div class="fStyle30_1F1F1F_bold_Eina02-Bold" v-if="item.singleStake">{{item.token0}} </div>
                <div class="fStyle30_1F1F1F_bold_Eina02-Bold uppercase" v-else>{{item.token0}}-{{item.token1}}</div>
                <div class="rate_wrap">
                  {{item.rate || 0 | subNumber(2)}}X
                </div>
              </div>
              <div class="flex_v_center_end" v-if="item.exchangeName != ''">
                <w-image-icon imgName="fst" v-if="item.exchangeName == 'Fstswap'"></w-image-icon>
                <w-image-icon imgName="pancake" v-else></w-image-icon>
              </div>
            </div>
            
        </div>
        <div class="flex_h_between_center mt_40">
            <div class="flex_h_center_center">
                <div class="fStyle28_1F1F1F_bold">LUSD</div>
                <div class="fStyle22_959595_regular ml_15">Earned</div>
            </div>
            <div class="fStyle28_3E51D2_bold">APY: {{item.apy | subNumber}}%</div>
        </div>
        <div class="input_wrap flex_h_between_center mt_15 circle_input">
            <div class="fStyle28_3E51D2_bold" :style="{'color': item.reward == 0 ? '#A5AFFC' : ''}">{{item.reward | subNumber}}</div>
            <button class="button fStyle28_ffffff mr_15 cirle_button" :class="[receiveDisable ? 'button_cancel': '']" @click="handleReceive">{{$t('receiveRewardLP')}}</button>
        </div>
        <div class="mt_30 flex_h_start_center">
            <div class="fStyle28_1F1F1F_bold" v-if="item.singleStake">{{item.token0}}</div>
            <div class="fStyle28_1F1F1F_bold" v-else>{{item.token0}}-{{item.token1}} LP</div>
            <div class="fStyle22_959595_regular ml_15">Staking</div>
        </div>
        <div class="mt_10 fStyle24_3E51D2 aLeft">{{item.stakeAmount | subNumber(12)}} <span v-if="item.stakeAmount>0">(≈{{item.stakeUSDT | subNumber(0)}} lusd)</span></div>
        <!-- <div class="mt_40 flex_h_between_center" v-if="true">
            <button class="button fStyle28_ffffff button_large" style="width: 100%">{{$t('authorize')}}</button>
        </div> -->
        <div class="mt_30 flex_h_between_center">
            <button class="button fStyle28_ffffff button_large" @click="handleUnStack" :class="unStackDisable ? 'button_cancel' : ''">{{$t('decompress')}}</button>
            <button class="button fStyle28_ffffff button_large" @click="handleStack">{{$t('addStake')}}</button>
        </div>
        <div class="mt_40 upercase flex_h_center_center fStyle22_A3AFFF_w6_Eina02-SemiBold" v-if="!auto" @click="changeHeight">{{$t('more')}}
            <div class="ml_10">
                <w-image-icon imgName="arrow_down_blue"></w-image-icon>
            </div>
        </div>
        <div class="mt_40 upercase flex_h_center_center fStyle22_A3AFFF_w6_Eina02-SemiBold" @click="changeHeight" v-else>{{$t('hidden')}}
            <div class="ml_10">
                <w-image-icon imgName="arrow_up_blue"></w-image-icon>
            </div>
        </div>
        <div class="list-content" :class="{'fullHeight':auto}">
          <div class="h_line"></div>
           <div class="flex_h_between_center">
              <div class="fStyle22_A3AFFF_w6_Eina02-SemiBold" style="color: #3E51D2">{{$t('totalCumulativeReward')}}</div>
              <div class="fStyle22_A3AFFF_w6_Eina02-SemiBold" style="color: #3E51D2">{{item.totalReward || '0' | subNumber(12)}} LUSD</div>
          </div>
          <div class="flex_h_between_center mt_30">
              <div class="fStyle24_1F1F1F">{{$t('totalStake')}}</div>
              <div class="fStyle24_1F1F1F" >{{item.totalStakeAmount | subNumber(12)}} (≈{{item.totalStakeUSDT | subNumber(0)}} lusd)</div>
          </div>
          <div class="flex_h_between_center mt_30">
              <div class="fStyle22_A3AFFF_w6_Eina02-SemiBold" v-if="item.singleStake">{{$t('get')}} {{item.token0}}</div>
              <div class="fStyle22_A3AFFF_w6_Eina02-SemiBold" v-else>{{$t('get')}} {{item.token0}} {{item.token1}} LP</div>
              <div class="fStyle22_A3AFFF_w6_Eina02-SemiBold flex_h_center_center" @click="gotoWeb(item.pairAddress)">{{$t('viewContract')}}
                  <div class="ml_10">
                      <w-image-icon imgName="viewContract"></w-image-icon>
                  </div>
              </div>
          </div>
          <div class="flex_h_between_center mt_30">
              <div class="fStyle22_A3AFFF_w6_Eina02-SemiBold">{{$t('prevStakeTime')}}：</div>
              <div class="fStyle22_A3AFFF_w6_Eina02-SemiBold">{{item.lastDepositAt | timestamp2Time}}</div>
          </div>
        </div>
        
    </div>
</div>
  
</template>

<script>
import WLogo from './WLogo.vue'
// import {NORMAL_ABI} from '@/common/abi'
import {store} from '@/store/index'
// import ContractUtils from '@/utils/ethersUtils/contractUtils'
import { Toast } from 'vant'
export default {
  props: {
    isLast: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      auto: false,
      token0Amount: 0,
      token1Amount: 0,
    }
  },
  components: {WLogo},
  computed: {
    provider() {
      return store.provider
    },
    contract() {
      return store.contract
    },
    unStackDisable() {
      if(this.item.stakeAmount == 0){
        return true
      }
      let nowTime = Date.now().toString().substring(0, 10)
      let endTime = Number(this.item.lastDepositAt) + Number(this.item.duration)
      if(nowTime - endTime > 0) {
        return false
      } else {
        return true
      }
    },
    receiveDisable() {
      if(this.item.reward == 0){
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    // this.getAmount()
  },
  methods: {
    async getAmount() {
      // const token0Contract = await new ContractUtils(this.item.token0, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
      // const [, token0BalanceOf] = await token0Contract.methodWithDecimal('balanceOf', token0Contract.decimal, [this.item.pairAddress])
      // this.token0Amount = token0BalanceOf
      // const token1Contract = await await new ContractUtils(this.item.token1, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
      // const[, token1BalanceOf]  = await token1Contract.methodWithDecimal('balanceOf', token1Contract.decimal, [this.item.pairAddress])
      // this.token1Amount = token1BalanceOf
    },
    handleStack() {
      this.$emit('on-stack', this.item)
    },
    handleUnStack() {
      if(this.unStackDisable){
        Toast(this.$t('nounstake'))
        return;
      }
      this.$emit('on-unstack', this.item)
    },
    handleReceive() {
      if(this.receiveDisable){
        return;
      }
      this.$emit('on-reward')
    },
    changeHeight() {
      this.auto = !this.auto
    },
    gotoWeb(address) {
      window.location.href = process.env.VUE_APP_BNBWEBSITE+'/address/'+address+'#code'
    }
  }
}
</script>

<style scoped lang="scss">
.list-item{
  overflow: hidden;
  .countdown_wrap{
      padding: 20px 0 20px 140px;
      background-color: #fff;
      box-shadow: 0px 4px 40px 0px rgba(35,52,208,0.1000);
      text-align: left;
      border-radius: 40px;
  }
  .box_wrap{
      background-color: #fff;
      border-radius: 40px;
      padding: 40px 48px;
      box-sizing: border-box;
      margin-top: 40px;
  }
  .rate_wrap{
    padding: 10px 18px;
    background: #A4ABE1;
    font-size: 26px;
    color: #fff;
    font-family: 'Eina02-SemiBold';
    font-weight: 600;
    border-radius: 20px;
    margin-left: 15px;
  }
  .h_line{
      width: 100%;
      height: 2px;
      background-color: #D8D8D8;
      margin-top: 20px;
      margin-bottom: 40px;
  }
  .colon {
    display: inline-block;
    margin: 0 12px;
    color: #A4ABE1;
  }
  .block {
    display: inline-block;
    text-align: center;
    background-color: #A4ABE1;
    padding: 16px;
    border-radius: 8px;
  }
  
  &.last-item{
    border-bottom: none;
    margin-bottom: 0;
  }
  .border_section{
    border: 1px solid #3B3E6B;
    padding: 24px 28px;
    margin-bottom: 25px;
    border-radius: 20px;
  }
  .button{
    // border-radius: 20px;
    // height: 64px;
    width: 270px;
    // margin:16px;
    height: 66px;
    &.button_large{
        height: 120px;
    }
    &.button_cancel{
        background-color: #A1AEFF;
        box-shadow: 0px 4px 20px 0px rgba(62,81,210,0.5000);
    }
    &.cirle_button{
      height: 88px;
      width: 210px;
    }
  }
  .list-content{
    height: 0px;
    overflow: hidden;
    transition: height 0.3s;
    &.fullHeight{
      height: 290px;
    }
  }
  .rotate{
    transform: rotate(180deg);
  }
}
</style>