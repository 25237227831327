import Vue from 'vue'
import App from './App.vue'
import './common/common.scss';
import router from './router'
import WImageIcon from '@/components/WImageIcon.vue'
import {calcShowAmount} from '@/utils/ethersUtils/helper/number'
import { Toast, Popup, Loading, Overlay, ActionSheet,RadioGroup, Radio, Cell, CellGroup, CountDown } from 'vant';
import 'vant/lib/toast/style';
import 'vant/lib/popup/style';
import 'vant/lib/loading/style';
import 'vant/lib/overlay/style';
import 'vant/lib/action-sheet/style';
import 'vant/lib/radio-group/style';
import 'vant/lib/radio/style';
import 'vant/lib/cell/style';
import 'vant/lib/cell-group/style';
import 'vant/lib/count-down/style';
import i18n from './il8n';
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(ActionSheet),
Vue.use(RadioGroup),
Vue.use(Radio),
Vue.use(Cell),
Vue.use(CellGroup),
Vue.use(CountDown);

Vue.component('w-image-icon', WImageIcon)

Vue.config.productionTip = false

Vue.filter('subNumber', (value, arg1 = 4) =>{
  return calcShowAmount(value, arg1)
})
Vue.filter('timestamp2Time', (timestamp) =>{
  if(!timestamp){
    return '0000:00:00 00:00'
  }
  var date = new Date(Number(timestamp) * 1000); 
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0"+date.getDate() : date.getDate()) + " ";
  var h = (date.getHours() < 10 ? "0"+date.getHours() : date.getHours()) + ":";
  var m = (date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes())  + ":";
  var s = (date.getSeconds() < 10 ? "0"+date.getSeconds() : date.getSeconds());
  return Y + M + D + h + m + s;
})
Vue.filter('subAddress', (value, arg1 = 17) =>{
  const address = value
  if (address !== '') {
    const prevStr = address.substring(0, arg1)
    const lastStr = address.substring(
      address.length - arg1,
      address.length,
    )
    return prevStr + '...' + lastStr
  } else {
    return ''
  }
  // return calcShowAmount(value, arg1)
})
Vue.filter('uppercase', (value) =>{
    return value.toUpperCase()
  // return calcShowAmount(value, arg1)
})


new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
