import { render, staticRenderFns } from "./WLogo.vue?vue&type=template&id=4bbc2bf1&scoped=true&"
import script from "./WLogo.vue?vue&type=script&lang=js&"
export * from "./WLogo.vue?vue&type=script&lang=js&"
import style0 from "./WLogo.vue?vue&type=style&index=0&id=4bbc2bf1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbc2bf1",
  null
  
)

export default component.exports