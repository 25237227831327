export function cacheResponseError (error) {
  let errorsArr = {
  }
  
  try {
    if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
      let errorTxt = 'Error:' + error.error.message
      for(let keyName in errorsArr) {
        if(error.error.message.indexOf(keyName) > -1) {
          errorTxt = 'Error:'+errorsArr[keyName]
          break;
        }
      }
      return errorTxt
    } else if (error.code === 'INSUFFICIENT_FUNDS') {
      return 'INSUFFICIENT_FUNDS'
    } else if (error.code === 4001 || error === 'cancelled') {
      return 'Cancelled'
    } else {
      let errorTxt = 'Error:' + error.data.message
      for(let keyName in errorsArr) {
        if(error.data.message.indexOf(keyName) > -1) {
          errorTxt = 'Error:'+errorsArr[keyName]
          break;
        }
      }
      return errorTxt
    }
  } catch (e) {
    return 'Error!'
  }
}
