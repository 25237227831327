import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home'
import Cast from '@/pages/Cast'
// import Staking from '@/pages/Staking'
import StakingLP from '@/pages/StakingLP'
import Dashboard from '@/pages/Dashboard'
import Repurchase from '@/pages/RepurchaseLusd'
import Upgrade from '@/pages/Upgrade'
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  redirect: '/staking',
  children: [
    {
      path: '/cast',
      name: 'Cast',
      component: Cast
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/staking',
      name: 'Staking',
      component: StakingLP
    },
    {
      path: '/repurchase',
      name: 'Repurchase',
      component: Repurchase
    },
    {
      path: '/upgrade',
      name: 'Upgrade',
      component: Upgrade
    },
  ]
}
    
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0,
    }
  },
})

export default router
