<template>
  <div class="pt_48">
    <div class="flex_h_between_center section_bg">
      <div class="flex_v_center_start aLeft">
        <div class="upercase fStyle54_1F1F1F_bold_eina">{{$t('swapCoin')}}</div>
        <div class="mt_15 fStyle28_4E4E4E_w6_einasemibold">{{$t('swapCoinTips')}}</div>
        <div class="flex_h_center_center mt_27" @click="showRule = true">
          <div class="fStyle28_A39DFF_w6_einasemibold">{{$t('ruleDesc')}}</div>
          <div class="ml_7"><w-image-icon imgName="arrow_right"></w-image-icon></div> 
        </div>
      </div>
      <div class="swap_position">
        <w-image-icon imgName="swap_bg"></w-image-icon>
      </div>
    </div>
    <div class="ph_48">
      <div class="limit_wrap p_relative flex_h_between_center burn_wrap" v-if="submitType == 'MINT'">
        <div class="flex_v_center_start">
          <div class="fStyle20_4E4E4E">{{$t('singleLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{singleMintAmount | subNumber}}</div>
        </div>
        <div class="flex_v_center_start">
          <div class="fStyle20_4E4E4E">{{$t('hourLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{hourMintAmount | subNumber}}</div>
        </div>
        <div class="flex_v_center_end">
          <div class="fStyle20_4E4E4E">{{$t('dayLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{dayMintAmount | subNumber}}</div>
        </div>
        <div class="limit_wrap_title fStyle24_4E4E4E_w6">{{$t('lusdLimit')}}</div>
      </div>
      <div class="limit_wrap p_relative flex_h_between_center" v-else>
        <div class="flex_v_center_start">
          <div class="fStyle20_4E4E4E">{{$t('singleLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{singleBurnAmount | subNumber}}</div>
        </div>
        <div class="flex_v_center_start">
          <div class="fStyle20_4E4E4E">{{$t('hourLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{hourBurnAmount | subNumber}}</div>
        </div>
        <div class="flex_v_center_end">
          <div class="fStyle20_4E4E4E">{{$t('dayLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{dayBurnAmount | subNumber}}</div>
        </div>
        <div class="limit_wrap_title fStyle24_4E4E4E_w6">{{$t('lusdLimit')}}</div>
      </div>
      <w-section class="mt_40">
        <div class="flex_h_end_center">
          <div class="fStyle22_C1C1CA_Eina02-Regular">{{$t('used')}}:{{(token0Contract && token0Contract.balanceOf || '0.00') | subNumber}}
            <span class="fStyle20_2334D0_w5 ml_7" @click="all">{{$t('max')}}</span>
          </div>
        </div>
        <div class="flex_h_between_center mt_22 input_wrap">
          <div class="flex_h_center_center" @click="showTokens = true">
            <div class="fStyle30_2334D0_bold">{{fromSymbol == '' ? $t('select') : fromSymbol}}</div>
            <div class="ml_7"><w-image-icon imgName="arrowUp"></w-image-icon></div>
          </div>
          <input type="text" class="aRight" v-model="amount" placeholder="0.00" style="width: 100%"/>
        </div>
        
        <div class="mt_30 flex_h_center_center" @click="exchange">
          <w-image-icon imgName="exchange"></w-image-icon>
        </div>
        <div class="flex_h_end_center mt_30">
          <div class="fStyle22_C1C1CA_Eina02-Regular">{{$t('used')}}:{{ (token1Contract && token1Contract.balanceOf || '0.00') | subNumber}}
          </div>
        </div>
        <div class="flex_h_between_center mt_22 input_wrap">
          <div class="flex_h_center_center" @click="showToken1Tokens = true">
              <div class="fStyle30_1F1F1F_bold">{{toSymbol == '' ? $t('select') : toSymbol}}</div>
            <div class="ml_7"> <w-image-icon imgName="arrowDown"></w-image-icon></div>
            </div>
          <input type="text" class="input aRight fStyle30_1F1F1F_bold" v-model="estimateUsd" placeholder="0.00" disabled/>
        </div>
      
        <div class="" v-if="amount != ''">
          <div class="flex_h_between_center mt_27">
            <div class="fStyle22_A8BCDA_w6_Eina02-SemiBold">{{$t('amount')}}</div>
            <div class="fStyle22_A8BCDA_Eina02-Regular">{{amount}} {{this.fromSymbol}}</div>
          </div>
          <div class="flex_h_between_center mt_27">
            <div class="fStyle22_A8BCDA_w6_Eina02-SemiBold">{{$t('receive')}}</div>
            <div class="fStyle22_A8BCDA_Eina02-Regular">≈{{estimateUsd | subNumber}} {{this.toSymbol}}</div>
          </div>
        </div>
      </w-section>

      <button class="button mt_120" :class="clickAble? '' : 'button_disable'" @click="submit">{{ submitType == 'BURN' ? $t('burn') : $t('mint')}}</button>
    </div>
    <!-- <button class="button button_white mt_50" @click="showRule = true">
      <div class="flex_h_center_center">
        <w-image-icon imgName="tips"></w-image-icon>
        <div class="ml_15">{{$t('ruleDesc')}}</div>
      </div>
    </button> -->
    <loading :show="showCastingFailed" @close="showCastingFailed=false">Failed</loading>
    <loading-success :show="showCastingSuccess" @close="showCastingSuccess=false">Success</loading-success>
    <loading-waiting :show="showWaiting" @close="showWaiting=false">Please be patient, the process takes about 2 minutes
    </loading-waiting>
  <van-popup v-model="showRule" round style="width: 80%">
      <div class="content_wrap">
        <div class="fStyle24_4E4E4E_w6">{{$t('ruleDesc1')}}</div>
        <div class="mt_22 fStyle26_1F1F1F">{{$t('ruleDesc2')}}</div>
        <div class="fStyle24_4E4E4E_w6">{{$t('ruleDesc3')}}</div>
        <div class="fStyle24_4E4E4E_w6">{{$t('ruleDesc4')}}</div>
        <div class="fStyle24_4E4E4E_w6">{{$t('ruleDesc5')}}</div>
        <div class="fStyle26_1F1F1F mt_22">{{$t('ruleDesc6')}}</div>
        <div class="fStyle24_4E4E4E_w6">{{$t('ruleDesc7')}}</div>
        <div class="fStyle26_1F1F1F mt_22">{{$t('ruleDesc8')}}</div>
        <div class="fStyle24_4E4E4E_w6">{{$t('ruleDesc9')}}</div>

        <div class="mt_40">
          <button class="button" @click="showRule=false">{{$t('know')}}</button>
        </div>
        
        <!--1) Single minting LUSD can not be greater than 0.5% of the liquidity pool
        2) Hourly minting LUSD can not be greater than 1% of the liquidity pool
        3) Daily minting of LUSD can not be greater than 2% of the liquidity pool

        Stake
        In the LUSD protocol, all users can perform StakingLUSD and thus gain revenue, the revenue is LUSD.

        Fee
        A fee of 0.3% is charged for each transfer, up to a maximum of 1 LUSD per transfer, with all fees charged going back to StakingLUSD. -->
      </div>
    </van-popup>
     <van-popup v-model="showTokens" round style="width: 80%">
       <div class="popupContent">
         <div class="flex_h_center_center fStyle30_1F1F1F_bold">Currency</div>
         <div class="close_position" @click="showTokens=false">
           <w-image-icon imgName="close"></w-image-icon>
         </div>
         <van-radio-group v-model="fromSymbol" class="mt_65" @change="selectToken0">
          <van-cell-group>
            <van-cell :title="item.symbol" clickable @click="fromSymbol = item.symbol" v-for="(item, index) in tokens" :key="index">
              <template #right-icon>
                <van-radio :name="item.symbol"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        
       </div>
     </van-popup>
      <van-popup v-model="showToken1Tokens" round style="width: 80%">
       <div class="popupContent">
         <div class="flex_h_center_center fStyle30_1F1F1F_bold">Currency</div>
         <div class="close_position" @click="showToken1Tokens=false">
           <w-image-icon imgName="close"></w-image-icon>
         </div>
         <van-radio-group v-model="toSymbol" class="mt_65" @change="selectToken1">
          <van-cell-group>
            <van-cell :title="item.symbol" clickable @click="toSymbol = item.symbol" v-for="(item, index) in toTokens" :key="index">
              <template #right-icon>
                <van-radio :name="item.symbol"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        
       </div>
     </van-popup>
  </div>
</template>
<script>
import WSection from '@/components/WSection'
import {store} from '@/store'
import { amount2Wei, wei2Amount } from '@/utils/ethersUtils/helper/number'
import {Toast} from 'vant'
import Loading from '@/components/Loading'
import LoadingSuccess from '@/components/LoadingSuccess'
import LoadingWaiting from '@/components/LoadingWaiting'
import ContractUtils from '@/utils/ethersUtils/contractUtils'
import {NORMAL_ABI, ROUTER_ABI, MINT_AIB} from '@/common/abi'
import Big from 'big.js'
// import { ethers } from 'ethers'
import EthersUtils from '@/utils/ethersUtils/index'
import { Decimal } from '@/utils/utils'
import { get } from '@/utils/request/http'
export default {
  data() {
    return {
      provider: null,
      amount: '',
      estimateUsd: '0.00', 
      clickAble: false,
      submitting: false,
      lite: process.env.VUE_APP_LITE,
      usd: process.env.VUE_APP_USD,
      usdt: process.env.VUE_APP_USDT,
      usdMint: process.env.VUE_APP_USD_MINT,
      fist: process.env.VUE_APP_FIST,
      router: process.env.VUE_APP_ROUTER,
      showWaiting: false,
      showRule: false,
      showCastingFailed: false,
      showCastingSuccess: false,
      fromSymbol: '',
      toSymbol: '',
      showTokens: false,
      showToken1Tokens: false,
      radio: '1',
      token0Contract: null,
      token1Contract: null,
      tokens: [],
      toTokens: [],
      submitType: 'MINT',
      singleMintAmount: 0,
      hourMintAmount: 0,
      dayMintAmount: 0,
      singleBurnAmount: 0,
      hourBurnAmount: 0,
      dayBurnAmount: 0,
      fistRouterAddress: '',
      fromConfig: null,
      price: '1'
    }
  },
  computed: {
    liteContract() {
      return store.liteContract
    },
    usdContract() {
      return store.usdContract
    },
    usdMintContract() {
      return store.usdMintContract
    }
  },
  watch: {
    usdMintContract() {
      this.getLimit();
      this.getUSDRPrice()
    },
    amount() {
      if(this.amount == '' || this.amount == 0){
        this.estimateUsd = 0.00
        this.clickAble = false
      } else {
        this.clickAble = true
        if(this.usdMintContract != null){
          this.getPrice()
        }
      }
    },
    fromSymbol() {
      if(this.fromSymbol != 'LUSD'){
        this.toTokens = this.tokens.filter((item)=> item.symbol == 'LUSD')
      } else {
        // this.toTokens = this.tokens.filter((item)=> item.symbol != this.fromSymbol )
        // TODO: cant use lusd->fist
        this.toTokens = this.tokens.filter((item)=> item.symbol == 'LITE' )
      }
    },
  },
  components: {WSection, Loading, LoadingSuccess, LoadingWaiting},
  async mounted() {
    await get("cast_list.json").then(res => {
      this.tokens = res;
    })
    const base =  await new EthersUtils()
    this.provider = base;
    for(let i = 0; i < this.tokens.length; i++) {
        if(this.tokens[i].symbol == 'FIST') {
          this.token0Contract = await new ContractUtils(this.tokens[i].address, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
          this.fromSymbol = 'FIST'
          this.fromConfig = this.tokens[i]
        }
        if(this.tokens[i].symbol == 'LUSD') {
          this.token1Contract = await new ContractUtils(this.tokens[i].address, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider) 
          this.toSymbol = 'LUSD'
        }
      }
    if(this.usdMintContract != null ) {
      this.getLimit()
      this.getUSDRPrice()
    }
  },
  methods: {
    async getUSDRPrice() {
      try {
        let path = [
          this.usd,
          this.usdt
        ]
        let amount = amount2Wei('1', this.usdContract.decimal)
        const routerContract = await new ContractUtils(this.router, ROUTER_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false, false)
        let [error, price] = await routerContract.method('getAmountsOut', [amount, path])
        if(error == null){
          price = wei2Amount(price[path.length-1], this.usdContract.decimal)
          this.price = price
        } else {
          this.price = '1'
        }
        console.log('this.price===',this.price)
      } catch (e) {
        this.price = '1'
      }
    },
    async getLimit() {
      let [res, mintLimit] = await this.usdMintContract.method('getMintLimit')
      if(res == null) {
        this.singleMintAmount = wei2Amount(mintLimit.maxAmount, this.usdContract.decimal)
        this.hourMintAmount = wei2Amount(mintLimit.hourLimit, this.usdContract.decimal)
        this.dayMintAmount = wei2Amount(mintLimit.dayLimit, this.usdContract.decimal)
      }
      let [res1, burnLimit] = await this.usdMintContract.method('getBurnLimit')
      if(res1 == null) {
        this.singleBurnAmount = wei2Amount(burnLimit.maxAmount, this.usdContract.decimal)
        this.hourBurnAmount = wei2Amount(burnLimit.hourLimit, this.usdContract.decimal)
        this.dayBurnAmount = wei2Amount(burnLimit.dayLimit, this.usdContract.decimal)
      }
    },
    async getPrice() {
      if(this.amount == '' || this.amount == 0){
        return;
      }
      if(this.toSymbol == '' || this.fromSymbol == ''){
        return;
      }
      let amount = amount2Wei(this.amount, this.token0Contract.decimal)
      let res
      if(this.fromSymbol == 'LUSD') {
        res = await this.usdMintContract.methodWithDecimal('getSwapToken', this.token1Contract.decimal, [amount, this.token1Contract.contract.address])
      } else {
        res = await this.usdMintContract.methodWithDecimal('getSwapUsd', this.usdContract.decimal, [amount, this.token0Contract.contract.address])
      }
      if(res[0] == null) {
        this.estimateUsd = res[1]
      }
    },
    submit() {
      if(this.submitType == 'MINT') {
        this.mintUsd()
      } else {
        this.burnUsd()
      }
    },
    async mintUsd() {
      if(this.submitting){
        return;
      }
      if(this.fromSymbol == '' || this.toSymbol == '') {
        Toast(this.$t('select'))
        return;
      }
      if(this.fromSymbol == this.toSymbol){
        Toast(this.$t('selectSample'))
        return;
      }
      if(!this.fromConfig.status) {
        Toast(this.$t('notLiteMintLimit'))
        return;
      }
      if(this.fromSymbol != 'LUSD' && this.toSymbol != 'LUSD'){
        Toast(this.$t('selectLUSD'))
        return;
      }
      if(this.amount == '' || this.amount == 0) {
        Toast(this.$t('enterAmount'))
        return;
      }
      if(Big(this.estimateUsd).gt(this.hourMintAmount)){
        Toast(this.$t('hourlimitPlaceholder'))
        return;
      }
      if(Big(this.estimateUsd).gt(this.dayMintAmount)){
        Toast(this.$t('daylimitPlaceholder'))
        return;
      }
      this.submitting = true
      this.showWaiting = true;
      if(this.fromConfig.isSwap && Decimal.sub(1, this.price).gt(0)) {
        this.swapLusd()
      } else if(this.fromConfig.mintRouter != ''){
        this.mintRouterUsd();
      } else {
        const amount = amount2Wei(this.amount, this.token0Contract.decimal)
        let [err] = await this.token0Contract.approveMethod(this.usdMint, this.token0Contract.decimal, this.amount, async ()=>{
          return this.usdMintContract.estimateMethod('mintUsd', async ()=>{
            this.getLimit()
            this.submitting = false
            this.showWaiting = false;
            this.showCastingSuccess = true;
            setTimeout(()=>{
              this.showCastingSuccess = false;
            }, 3000)
            this.amount = ''
            this.token0Contract.getBalance()
            this.token1Contract.getBalance()
            Toast(this.$t('success'))
            return [null]
          }, [this.token0Contract.contract.address,amount])
        })  
        if(err != null) {
          this.submitting = false
          this.showWaiting = false;
          this.amount = ''
          this.showCastingFailed = true
          setTimeout(()=>{
            this.showCastingFailed = false;
          }, 3000)
          this.getLimit()
          Toast(err)
        }
      }
    },
    async mintRouterUsd() {
      const amount = amount2Wei(this.amount, this.token0Contract.decimal)
      let routerAddress = this.fromConfig.mintRouter
      let mintRouterContract = await new ContractUtils(routerAddress, MINT_AIB, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
      console.log('mintRouterUsd====', routerAddress, this.token0Contract.contract.address,amount)
      let [err] = await this.token0Contract.approveMethod(routerAddress, this.token0Contract.decimal, this.amount, async ()=>{
        return mintRouterContract.estimateMethod('mintUsd', async ()=>{
          this.getLimit()
          this.submitting = false
          this.showWaiting = false;
          this.showCastingSuccess = true;
          setTimeout(()=>{
            this.showCastingSuccess = false;
          }, 3000)
          this.amount = ''
          this.token0Contract.getBalance()
          this.token1Contract.getBalance()
          Toast(this.$t('success'))
          return [null]
        }, [this.token0Contract.contract.address,amount])
      })  
      if(err != null) {
        this.submitting = false
        this.showWaiting = false;
        this.amount = ''
        this.showCastingFailed = true
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        this.getLimit()
        Toast(err)
      }
    },
    async burnUsd() {
      if(this.submitting){
        return;
      }
      if(this.fromSymbol == '' || this.toSymbol == '') {
        Toast(this.$t('select'))
        return;
      }
      if(this.fromSymbol == this.toSymbol){
        Toast(this.$t('selectSample'))
        return;
      }
      if(this.fromSymbol != 'LUSD' && this.toSymbol != 'LUSD'){
        Toast(this.$t('selectLUSD'))
        return;
      }
      if(this.amount == '' || this.amount == 0) {
        Toast(this.$t('enterAmount'))
        return;
      }
      this.submitting = true
      this.showWaiting = true;
      const amount = amount2Wei(this.amount, this.token0Contract.decimal)
      let [err] = await this.token0Contract.approveMethod(this.usdMint, this.token0Contract.decimal, this.amount, async ()=>{
        return this.usdMintContract.estimateMethod('burnUsd', async ()=>{
          this.submitting = false
          this.showWaiting = false;
          this.showCastingSuccess = true;
          setTimeout(()=>{
            this.showCastingSuccess = false;
          }, 3000)
          this.amount = ''
          this.token0Contract.getBalance()
          this.token1Contract.getBalance()
          this.getLimit()
          Toast(this.$t('success'))
          return [null]
        }, [this.token1Contract.contract.address, amount])
      })  
      if(err != null) {
        this.submitting = false
        this.showWaiting = false;
        this.amount = ''
        this.showCastingFailed = true
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        this.getLimit()
        Toast(err)
      }
    },
    async swapLusd() {
      let routerContract = await new ContractUtils(this.fromConfig.router, ROUTER_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false, false)
      let [err] = await this.token0Contract.approveMethod(this.fromConfig.router, this.token0Contract.decimal, this.amount, async () => {
        let amountIn = amount2Wei(this.amount, this.token0Contract.decimal)
        let path = [this.fromConfig.address, this.usd]
        let [err,amountOut] = await routerContract.method('getAmountsOut',[amountIn, path])
        if(err != null){
          return err
        }
        let amountOutMin = amount2Wei(Decimal.mul(amount2Wei(amountOut[1], 0), 0.99), 0)
        const timeStr = Date.now().toString().substring(0, 10)
        let deadline = parseInt(timeStr) + 600
        return routerContract.estimateMethod('swapExactTokensForTokens', ()=> {
          this.getLimit()
          this.submitting = false
          this.showWaiting = false;
          this.showCastingSuccess = true;
          setTimeout(()=>{
            this.showCastingSuccess = false;
          }, 3000)
          this.amount = ''
          this.token0Contract.getBalance()
          this.token1Contract.getBalance()
          Toast(this.$t('success'))
          return [null]
        }, [amountIn, amountOutMin, path, this.provider.address, deadline])
      })
      if(err != null) {
          this.submitting = false
          this.showWaiting = false;
          this.amount = ''
          this.showCastingFailed = true
          setTimeout(()=>{
            this.showCastingFailed = false;
          }, 3000)
          this.getLimit()
          Toast(err)
        }
    },
    async exchange() {
      [this.fromSymbol, this.toSymbol] = [this.toSymbol, this.fromSymbol]
      const temp = this.token0Contract;
      this.token0Contract = this.token1Contract
      for(let i = 0; i < this.tokens.length; i++) {
        if(this.fromSymbol == this.tokens[i].symbol) {
          this.fromConfig = this.tokens[i]
        }
      }
      this.token1Contract = temp
      if(this.fromSymbol === 'LUSD') {
        this.submitType = 'BURN'
        if(this.toSymbol != 'LITE') {
          this.toSymbol = 'LITE'
          for(let i = 0; i < this.tokens.length; i++) {
            if(this.tokens[i].symbol == 'LITE') {
              this.token1Contract = await new ContractUtils(this.tokens[i].address, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
            }
          }
        }
      } else {
        this.submitType = 'MINT'
      }
      this.getPrice()
    },
    async all() {
      // if(this)
      let balance = (this.token0Contract && this.token0Contract.balanceOf || '0.00')
      if(this.fromSymbol == 'LUSD'){
        if(Big(balance).gt(this.hourBurnAmount)) {
          if(Big(this.dayBurnAmount).gt(this.hourBurnAmount)) {
            this.amount = this.dayBurnAmount
          } else {
            this.amount = this.hourBurnAmount
          }
        } else {
          this.amount = balance
        }
      }else {
        let amount = amount2Wei(1, this.token0Contract.decimal)
        let [res, price] = await this.usdMintContract.methodWithDecimal('getSwapUsd', this.usdContract.decimal, [amount, this.token0Contract.contract.address])
        let hourLimitCoin = 0
        let dayLimitCoin = 0
        if(res == null) {
          hourLimitCoin = Decimal.div(this.hourMintAmount, price)
          dayLimitCoin = Decimal.div(this.dayMintAmount, price)
          if(Big(balance).gt(hourLimitCoin)) {
            if(Big(hourLimitCoin).gt(dayLimitCoin)){
              this.amount = dayLimitCoin.toFixed(0)
            } else {
              this.amount = hourLimitCoin.toFixed(0)
            }
          } else {
            this.amount = balance
          }
        }
      }
    },
    async selectToken0(name) {
      for(let i = 0; i < this.tokens.length; i++) {
        if(this.tokens[i].symbol == name) {
          this.token0Contract = await new ContractUtils(this.tokens[i].address, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
          this.fromConfig = this.tokens[i]
          this.showTokens = false;
          if(name === 'LUSD') {
            this.submitType = 'BURN'
          } else {
            this.submitType = 'MINT'
          }
        }
      }
    },
    async selectToken1(name) {
      for(let i = 0; i < this.tokens.length; i++) {
        if(this.tokens[i].symbol == name) {
          this.token1Contract = await new ContractUtils(this.tokens[i].address, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
          this.showToken1Tokens = false;
          if(name === 'LUSD') {
            this.submitType = 'MINT'
            // this.fromSymbol = ''
            // this.token0Contract = null
          } else {
            this.submitType = 'BURN'
            //  this.fromSymbol = 'LUSD'
            // this.token0Contract = await new ContractUtils(this.usd, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.content_wrap{
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
}
.van-cell__title, .van-cell__value{
  text-align: left;
}
.section_bg{
  padding-left: 48px;
}
.limit_wrap{
  box-shadow: 0px 4px 40px 0px rgba(35, 52, 208, 0.1);
  border: 2px solid #1F1F1F;
  padding: 30px 48px;
  border-radius: 40px;
  background-color: #fff;
  .limit_wrap_title{
    position: absolute;
    border: 2px solid #1F1F1F;
    padding: 5px 18px;
    background-color: #fff;
    border-radius: 14px;
    top: -30px;
    left: 50px;
  }
  &.burn_wrap{
    box-shadow: 0px 4px 40px 0px rgba(35, 52, 208, 0.1);
    border: 2px solid #4C40F7;
    .limit_wrap_title{
      border: 2px solid #4C40F7;
    }
  }
}
.popupContent{
  padding: 30px;
  position: relative;
  .close_position{
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
</style>