<template>
  <div class="pt_48 pb_48">
    <div class="flex_h_between_center section_bg">
      <div class="flex_v_center_start">
        <div class="upercase fStyle54_1F1F1F_bold_eina">{{$t('dashboard')}}</div>
        <div class="mt_27 fStyle28_A5A5A5_w6_einasemibold">1 LUSD ≈ {{price | subNumber(4)}} USDT</div>
        <div class="flex_h_start_center mt_77">
          <w-image-icon imgName="lusdt"></w-image-icon>
          <div class="fStyle24_4E4E4E_w6_Eina02-SemiBold ml_7">{{$t('storeAmount')}}</div>
        </div>
        <div class="fStyle50_1F1F1F_w6_Eina02-SemiBold mt_10">{{totalStaked | subNumber}}&nbsp; <span class="fStyle30_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
      </div>
      <div class="swap_position">
        <w-image-icon imgName="dashboard_bg"></w-image-icon>
      </div>
    </div>
    <div class="copy_wrap flex_h_between_center">
      <span  class="fStyle24_2334D0"><span class="fStyle24_2334D0__w6_Eina02-SemiBold">LUSD: </span>{{lusd | subAddress(8)}}</span>
      <button class="button button_desc" @click="h5Copy(lusd)">{{$t('copy')}}</button>
    </div>
    <div class="ph_48">
       <w-section class="mt_40">
      <div class="flex_h_between_center">
        <div class="flex_v">
          <div class="flex_h_start_center">
            <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold ml_7 upercase">{{$t('yieldReserve')}}</div>
          </div>
          <div class="fStyle30_1F1F1F_bold_Eina02-SemiBold mt_10">{{totalReward | subNumber}}&nbsp; <span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
        </div>
        <div class="flex_v">
          <div class="flex_h_start_center">
            <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold ml_7 upercase">{{$t('lusdTotalSupply')}}</div>
          </div>
          <div class="fStyle30_1F1F1F_bold_Eina02-SemiBold mt_10">{{lusdTotalSupply | subNumber}}&nbsp; <span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
        </div>
      </div>
    </w-section>
    <w-section class="mt_40 dashboard_bg pv_56">
        <div class="flex_h_start_center">
          <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold ml_7 upercase">{{$t('liteBalance')}}</div>
        </div>
        <div class="fStyle30_1F1F1F_bold_Eina02-SemiBold mt_10 aLeft">{{liteContract && liteContract.balanceOf || '0' | subNumber}}&nbsp; <span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LITE</span></div>
        <!-- <div class="h_line"></div> -->
        <div class="flex_h_start_center mt_65">
          <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold ml_7 upercase">{{$t('lusdBalance')}}</div>
        </div>
        <div class="fStyle30_1F1F1F_bold_Eina02-SemiBold mt_10 aLeft">{{usdContract && usdContract.balanceOf || '0' | subNumber}}&nbsp; <span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
        <!-- <div class="h_line"></div> -->
      <div class="aLeft mt_65">
        <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold uppercase">{{$t('myDepositAmount')}}</div>
        <div class="fStyle30_1F1F1F_bold_Eina02-SemiBold mt_10">{{stakedOf | subNumber}}&nbsp; <span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
      </div>
    </w-section>
    </div>
   
  </div>
</template>
<script>
import {store} from '@/store'
import WSection from '@/components/WSection'
import { wei2Amount, amount2Wei } from '@/utils/ethersUtils/helper/number'
import { ROUTER_ABI, DEPOSIT_ABI} from '@/common/abi'
import ContractUtils from '@/utils/ethersUtils/contractUtils'
import { h5Copy } from '@/utils/utils'
export default {
  components: {WSection},
  mixins: [h5Copy],
  computed: {
    provider() {
      return store.provider
    },
    qLunaContract() {
      return store.qLunaContract
    },
    ustContract() {
      return store.ustContract
    },
    usdContract() {
      return store.usdContract
    },
    usdStakeLPContract() {
      return store.usdStakeLPContract
    },
    liteContract() {
      return store.liteContract
    }
  },
  data() {
    return {
      stakedOf: 0,
      totalStaked: 0,
      totalReward: 0,
      lusdTotalSupply: 0,
      depositContract: null,
      lusd: process.env.VUE_APP_USD,
      usdt: process.env.VUE_APP_USDT,
      router: process.env.VUE_APP_ROUTER,
      deposit: process.env.VUE_APP_USD_DEPOSIT,
      price: '1.0',
    }
  },
  mounted() {
    if(this.provider != null){
      this.initContract();
    }
  },
  watch: {
    provider() {
      if(this.contract == null){
        this.initContract();
      }
    },
    usdStakeLPContract() {
      this.init()
    },
    
  },
  methods: {
    async initContract() {
      this.depositContract = await new ContractUtils(this.deposit, DEPOSIT_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
      if(this.usdStakeLPContract != null){
        this.init()
      }
    },
     async init() {
      
      let calls  = [
        this.depositContract.batchCall.totalStaked(),
        this.depositContract.batchCall.totalUsedFees(),
        this.depositContract.batchCall.stakedOf(this.provider.address),
        this.usdContract.batchCall.totalSupply(),
      ]
      let [totalStaked,totalReward, stakedOf, lusdTotalSupply] = await this.provider.all(calls);
      this.totalStaked = wei2Amount(totalStaked, this.usdContract.decimal);
      this.totalReward = wei2Amount(totalReward, this.usdContract.decimal);
      this.stakedOf = wei2Amount(stakedOf, this.usdContract.decimal);
      this.lusdTotalSupply = wei2Amount(lusdTotalSupply, this.usdContract.decimal);
      this.getUSDRPrice()
    },
    async getUSDRPrice() {
      try {
        let path = [
          this.lusd,
          this.usdt
        ]
        let amount = amount2Wei('1', this.usdContract.decimal)
        const routerContract = await new ContractUtils(this.router, ROUTER_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false, false)
        let [error, price] = await routerContract.method('getAmountsOut', [amount, path])
        if(error == null){
          price = wei2Amount(price[path.length-1], this.usdContract.decimal)
          this.price = price
        } else {
          this.price = '1'
        }
      } catch (e) {
        this.price = '1'
      }
    },
  }
}
</script>
<style scoped lang="scss">
.section_bg{
    padding-left: 48px;
    position: relative;
    .swap_position{
      position: absolute;
      right: 0;
      right: 0;
    }
  }
  .dashboard_bg{
    background-image: url('../assets/dashboard_section_bg.png');
    background-repeat: no-repeat;
    background-size: 255px 405px;
    background-position: 100% 100%;
    &.pv_56{
      padding-top: 56px;
      padding-bottom: 56px;
    }
  }
.cube{
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 20px;
  
  &.cube_blue{
    background-color: #2334D0;
  }
  &.cube_light_blue{
    background-color: #06DEF9;
  }
}
.copy_wrap{
  border: 1px solid #3E51D2;
  padding: 30px 40px;
  border-radius: 20px;
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 30px;
}
</style>