<template>
  <van-popup v-model="show" @click-overlay="close" :close-on-click-overlay="false" style="width: 100%; height: 100%;">
    <div class="popup_content"> 
      <div class="flex_h_between_center">
        <w-image-icon imgName="popup_logo"></w-image-icon>
        <div class="" @click="close">
          <w-image-icon imgName="close"></w-image-icon>
        </div>
      </div>
      <div class="link mt_120" @click="goto('Dashboard', 0)" :class="currRoute == 0 ? 'curr' : ''">
        {{$t('DASHBOARD')}}
      </div>
      <div class="link uppercase" @click="goto('Cast', 1)" :class="currRoute == 1 ? 'curr' : ''">
        {{$t('swapcoins')}}
      </div>
      <div class="link" @click="goto('Staking', 2)" :class="currRoute == 2 ? 'curr' : ''">
        {{$t('earn')}}
      </div>
      <div class="link" @click="goto('Repurchase', 2)" :class="currRoute == 3 ? 'curr' : ''">
        {{$t('lusdMarket')}}
      </div>
      <div class="link" @click="goto('Upgrade', 2)" :class="currRoute == 4 ? 'curr' : ''">
        {{$t('upgrade')}}
      </div>
      
      
      <!-- <div class="link" @click="goto('StakingLP', 3)" :class="currRoute == 3 ? 'curr' : ''">
        {{$t('stakingLP')}}
      </div> -->
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    label: String
  },
  beforeRouteEnter(to,from,next){
    next((el)=>{
      console.log(el)
      el.showChoiceCoin = to.name === 'Staking'
      return true
    })
  },
  beforeRouteUpdate(to,from,next){
    next((el)=>{
      console.log(to, from, next)
      el.showChoiceCoin = to.name === 'Staking'
      return true
    })
  },
  data() {
    return {
      currRoute: 0
    }
  },
  mounted() {
    if(this.$route.name == 'Cast') {
      this.currRoute = 1
    } else if(this.$route.name == 'Dashboard') {
      this.currRoute = 0
    } else if(this.$route.name == 'Repurchase'){
      this.currRoute = 3
    } else if(this.$route.name == 'Upgrade'){
      this.currRoute = 4
    }  else {
      this.currRoute = 2
    }
  },
  methods: {
    goto(routeName, index) {
      this.$emit('close')
      this.currRoute = index
      this.$router.push(routeName)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup_content{
  min-height: 100vh;
  background-color: #fff;
  background-image: url('../assets/popup_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 921px;
  background-position: 100% 100%;
  padding: 80px 54px 0 54px;
  box-sizing: border-box;

  .link{
    font-size: 64px;
    font-family: 'Eina02-Bold';
    font-weight: bold;
    color: #111029;
    line-height: 140px;
    text-align: left;
    &.curr{
      color: #4C40F7;
    }
  }
}
</style>