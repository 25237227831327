<template>
  <div class="home_container">
    <div class="flex_h_between_center navbar">
      <div class="flex_h_center_center">
        <w-image-icon imgName="logo"></w-image-icon>
        <div class="fStyle38_ffffff_bold_number ml_40 letterSpace">LITE</div>
      </div>
      <div class="flex_h_center_center">
        <div class="" @click="toggleLang">
          <w-image-icon imgName="zh" v-if="$i18n.locale == 'en'"></w-image-icon>
          <w-image-icon imgName="en" v-else></w-image-icon>
        </div>
        <div class="border_wrap fStyle20_FFFFFF_w5 ml_40">
          {{this.provider && this.provider.address || 'connect' | subAddress(4)}}
        </div>
        <div class="ml_40" @click="showSidebar = true">
          <w-image-icon imgName="menu"></w-image-icon>
        </div>
      </div>
    </div>
    <router-view />
    <w-sidebar-popup :show="showSidebar" @close="showSidebar=false"></w-sidebar-popup>
  </div>
</template>

<script>
import EthersUtils from '@/utils/ethersUtils/index'
import ContractUtils from '@/utils/ethersUtils/contractUtils'
import {store,mutations} from '@/store'
import {MINT_AIB, NORMAL_ABI, STAKELP_ABI} from '@/common/abi'
import { ethers } from 'ethers'
import {Toast} from 'vant'
import WSidebarPopup from '@/components/WSidebarPopup'
export default {
  data() {
    return {
      provider: null,
      contract: null,
      lite: process.env.VUE_APP_LITE,
      usd: process.env.VUE_APP_USD,
      usdMint: process.env.VUE_APP_USD_MINT,
      // usdStack: process.env.VUE_APP_USD_STAKE,
      usdStackLP: process.env.VUE_APP_USD_STAKELP,
      token: '',
      usdtToken: '',
      showChoiceCoin: false,
      rewardTo: '',
      tokenList: [],
      showTokenList: false,
      rewardToSymbol: '',
      showSidebar: false
    }
  },
  components: {WSidebarPopup},
  beforeRouteEnter(to,from,next){
    next((el)=>{
      el.showChoiceCoin = to.name === 'Staking'
      return true
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.showChoiceCoin = to.name === 'Staking'
    next(true)
  },
  computed: {
    usdContract() {
      return store.usdContract
    }
  },
  mounted() {
    this.initContract();
  },
  methods: {
    async initContract() {
      const base =  await new EthersUtils()
      this.provider = base;
      mutations.setStateVal('provider', base)
      const liteContract = await new ContractUtils(this.lite, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
      mutations.setStateVal('liteContract', liteContract)
      const usdContract = await new ContractUtils(this.usd, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
      mutations.setStateVal('usdContract', usdContract)
      const usdMintContract = await new ContractUtils(this.usdMint, MINT_AIB, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
      mutations.setStateVal('usdMintContract', usdMintContract)
      const usdStakeLPContract = await new ContractUtils(this.usdStackLP, STAKELP_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
      mutations.setStateVal('usdStakeLPContract', usdStakeLPContract)
    },
    init() {
      this.getRewardTo()
      this.getRewardList()
    },
    async getRewardTo() {
      let [err, res] = await this.usdContract.method('rewardTo', [this.provider.address])
      if(err == null) {
        this.rewardTo = res == ethers.constants.AddressZero  ? '' : res
        if(this.rewardTo != '') {
           let tokenContract = new ethers.Contract(this.rewardTo, NORMAL_ABI, this.provider.signer)
           let symbol =  await tokenContract.symbol()
           this.rewardToSymbol = symbol
        }
      }
    },
    async getRewardList() {
      let [err, len] = await this.usdContract.method('rewardTokenLength')
      if(err == null){
        let tokenList = []
        for (let index = 0; index < len; index++) {
            let [,token] =  await this.usdContract.method('rewardTokens', [index])
            let tokenContract = new ethers.Contract(token, NORMAL_ABI, this.provider.signer)
            let symbol =  await tokenContract.symbol()
            tokenList.push({
              symbol,
              address: token,
            })
        }
        this.tokenList = tokenList
      }
    },
    async onSelect(item) {
      if(this.submitting) {
        return;
      }
      this.submitting = true;
      this.showTokenList = false;
      if(item.address.toUpperCase() == this.rewardTo.toUpperCase()){
        return;
      }
      let [err] = await this.usdContract.estimateMethod('setRewardTo', () => {
        this.submitting = false;
        this.rewardTo = item.address;
        return [null]
      }, [item.address])
      if(err != null) {
        this.submitting = false;
        Toast(err)
      }
    },
    toggleLang() {
      this.$i18n.locale = this.$i18n.locale == 'en' ? 'zh' : 'en'
      window.localStorage.setItem('LANGUAGE', this.$i18n.locale)
    }
  }
}
</script>

<style scoped lang="scss">
.home_container{
  background-color: #F3F5F9;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 100px;
  .navbar{
    background: linear-gradient(270deg, #693CDE 0%, #4C40F7 100%);
    padding: 33px 48px;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 500PX;
    box-sizing: border-box;
    z-index: 10;
  }
  .border_wrap{
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 40px;
  }
  .link{
    font-size: 28px;
    color: #B5BBEF;
    font-weight: 600;
    &.router-link-active{
      color: #fff;
    }
  }
  .choiceCoin{
    border: 1px solid #FF9F16;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    .text{
      padding-left: 34px;
      padding-right: 34px;
      font-size: 28px;
      font-weight: bold;
      color: #FF9F16;
    }
    .btn{
      padding: 10px 23px;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      background:#FF9F16;
      border-radius: 20px 0 0 20px;
    }
  }
  .actions_items{
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    &.curr{
      opacity: 0.6;
    }
    &.no_border{
      border-bottom: none
    }
  }
  .letterSpace{
    letter-spacing: 20px;
  }
}

</style>