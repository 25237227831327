<template>
  <div class="logo_wrap">
    <w-image-icon :imgName="token0"></w-image-icon>
    <div class="position">
      <w-image-icon :imgName="token1"></w-image-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WLogo',
  props: {
    token0: String,
    token1: String
  }
}
</script>

<style scoped>
.logo_wrap{
  /* padding: 0 20px 10px 0; */
  padding-right: 46px;
  position: relative;
}
.logo_wrap .position{
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>