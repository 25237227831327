<template>
  <div class="section_outter">
    <slot />
  </div>
</template>
<script>

export default {
  name: 'WSection'
  
}
</script>

<style scoped>
.section_outter{
  background: #fff;
  border-radius: 40px;
  box-shadow: 0px 4px 40px 0px rgba(35,52,208,0.1);
  padding: 38px;
  box-sizing: border-box;
}
</style>