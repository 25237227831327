<template>
  <div class="pt_48">
    <div class="flex_h_between_center section_bg">
      <div class="flex_v_center_start aLeft">
        <div class="upercase fStyle54_1F1F1F_bold_eina">{{$t('swapCoinLusd')}}</div>
        <div class="mt_15 fStyle28_4E4E4E_w6_einasemibold">{{$t('swapCoinLusdTips')}}</div>
      </div>
      <div class="swap_position">
        <w-image-icon imgName="swap_bg"></w-image-icon>
      </div>
    </div>
    <div class="ph_48">
      <div class="limit_wrap p_relative flex_h_between_center burn_wrap">
        <div class="flex_v_center_start">
          <div class="fStyle20_4E4E4E">{{$t('buyLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{balanceAmount | subNumber}}</div>
        </div>
        
        <div class="flex_v_center_start">
          <div class="fStyle20_4E4E4E">{{$t('sellLimit')}}</div>
          <div class="mt_10 fStyle24_4E4E4E_w6">{{singleAmount | subNumber}}</div>
        </div>
        <div class="limit_wrap_title fStyle24_4E4E4E_w6">{{$t('lusdRepurchaseLimit')}}</div>

      </div>
      <w-section class="mt_40">
        <div class="tags flex_h_start_center">
          <div class="tag fStyle24_4E4E4E_w6" :class="submitType == 'BUY' ? 'curr':''" @click="submitType = 'BUY'">{{$t('buy')}}</div>
          <div class="tag fStyle24_4E4E4E_w6" :class="submitType != 'BUY' ? 'curr':''" @click="submitType = 'SELL'">{{$t('sell')}}</div>
        </div>
        <div class="flex_h_end_center mt_22">
          <div class="fStyle22_C1C1CA_Eina02-Regular" v-if="submitType == 'BUY'">{{$t('used')}}:{{(usdtContract && usdtContract.balanceOf || '0.00') | subNumber}}
            <span class="fStyle20_2334D0_w5 ml_7" @click="all">{{$t('max')}}</span>
          </div>
          <div class="fStyle22_C1C1CA_Eina02-Regular" v-else>{{$t('used')}}:{{(singleAmount || '0.00') | subNumber}}
            <span class="fStyle20_2334D0_w5 ml_7" @click="all">{{$t('max')}}</span>
          </div>
        </div>
        <div class="flex_h_between_center mt_22 input_wrap">
          <div class="flex_h_center_center">
            <div class="fStyle30_2334D0_bold">{{submitType == 'BUY' ? 'USDT' : 'LUSD'}}</div>
            <div class="ml_7"><w-image-icon imgName="arrowUp"></w-image-icon></div>
          </div>
          <input type="text" class="aRight" v-model="amount" placeholder="0.00" style="width: 100%"/>
        </div>
      </w-section>

      <button class="button mt_120" :class="clickAble? '' : 'button_disable'" @click="submit">{{ submitType == 'BUY' ? $t('buy') : $t('sell')}}</button>
    </div>
    <loading :show="showCastingFailed" @close="showCastingFailed=false">Failed</loading>
    <loading-success :show="showCastingSuccess" @close="showCastingSuccess=false">Success</loading-success>
    <loading-waiting :show="showWaiting" @close="showWaiting=false">Please be patient, the process takes about 2 minutes
    </loading-waiting>
  </div>
</template>
<script>
import WSection from '@/components/WSection'
import {store} from '@/store'
import { amount2Wei, wei2Amount } from '@/utils/ethersUtils/helper/number'
import {Toast} from 'vant'
import Loading from '@/components/Loading'
import LoadingSuccess from '@/components/LoadingSuccess'
import LoadingWaiting from '@/components/LoadingWaiting'
import ContractUtils from '@/utils/ethersUtils/contractUtils'
import {NORMAL_ABI, ROUTER_ABI, REPURCHASE_ABI} from '@/common/abi'
import EthersUtils from '@/utils/ethersUtils/index'
import { Decimal } from '@/utils/utils'
export default {
  data() {
    return {
      provider: null,
      amount: '',
      estimateUsd: '0.00', 
      clickAble: false,
      submitting: false,
      repurchaseContract: null,
      usdtContract: null,
      lite: process.env.VUE_APP_LITE,
      usd: process.env.VUE_APP_USD,
      usdt: process.env.VUE_APP_USDT,
      usdMint: process.env.VUE_APP_USD_MINT,
      fist: process.env.VUE_APP_FIST,
      router: process.env.VUE_APP_ROUTER,
      repurchase: process.env.VUE_APP_REPURCHASE,
      showWaiting: false,
      showRule: false,
      showCastingFailed: false,
      showCastingSuccess: false,
      fromSymbol: '',
      toSymbol: '',
      showTokens: false,
      showToken1Tokens: false,
      radio: '1',
      token0Contract: null,
      token1Contract: null,
      tokens: [],
      toTokens: [],
      submitType: 'BUY',
      balanceAmount: 0,
      singleAmount: 0,
      singleMintAmount: 0,
      hourMintAmount: 0,
      dayMintAmount: 0,
      singleBurnAmount: 0,
      hourBurnAmount: 0,
      dayBurnAmount: 0,
      fistRouterAddress: '',
      fromConfig: null,
      price: '1'
    }
  },
  computed: {
    usdContract() {
      return store.usdContract
    },
  },
  watch: {
    usdContract() {
      this.getLimit();
    },
    amount() {
      if(this.amount == '' || this.amount == 0){
        this.estimateUsd = 0.00
        this.clickAble = false
      } else {
        this.clickAble = true
        this.getUSDRPrice()
      }
    },
  },
  components: {WSection, Loading, LoadingSuccess, LoadingWaiting},
  async mounted() {
    const base =  await new EthersUtils()
    this.provider = base;
    this.repurchaseContract = await new ContractUtils(this.repurchase, REPURCHASE_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
    this.usdtContract = await new ContractUtils(this.usdt, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
    if(this.usdContract != null ) {
      this.getLimit()
    }
  },
  methods: {
    async getUSDRPrice() {
      try {
        let path = [
          this.usd,
          this.usdt
        ]
        let amount = amount2Wei('1', this.usdContract.decimal)
        const routerContract = await new ContractUtils(this.router, ROUTER_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false, false)
        let [error, price] = await routerContract.method('getAmountsOut', [amount, path])
        if(error == null){
          price = wei2Amount(price[path.length-1], this.usdContract.decimal)
          this.price = price
        } else {
          this.price = '1'
        }
        console.log('this.price===',this.price)
      } catch (e) {
        this.price = '1'
      }
      if(this.submitType == 'BUY') {
        this.estimateUsd = Decimal.mul(this.price, this.amount).toFixed()
      } else {
        this.estimateUsd = Decimal.div(this.amount, this.price).toFixed()
      }
    },
    async getLimit() {
      let [res, singleAmount] = await this.repurchaseContract.methodWithDecimal('userAmount', this.usdContract.decimal, [this.provider.address])
      if(res == null) {
        this.singleAmount = singleAmount
      }

      let [res2, balanceAmount] = await this.usdContract.methodWithDecimal('balanceOf', this.usdContract.decimal, [this.repurchase])
      if(res2 == null) {
        this.balanceAmount = balanceAmount;
      }
    },
  
    submit() {
      if(this.submitType == 'BUY') {
        this.buyUsd()
      } else {
        this.sellUsd()
      }
    },
    async buyUsd() {
      if(this.submitting){
        return;
      }
      if(this.amount == '' || this.amount == 0) {
        Toast(this.$t('enterAmount'))
        return;
      }
      // if(Big(this.estimateUsd).gt(this.singleAmount)){
      //   Toast(this.$t('lusdlimitPlaceholder'))
      //   return;
      // }
      this.submitting = true
      this.showWaiting = true;
        const amount = amount2Wei(this.amount, this.usdtContract.decimal)
        let [err] = await this.usdtContract.approveMethod(this.repurchase, this.usdtContract.decimal, this.amount, async ()=>{
          return this.repurchaseContract.estimateMethod('buy', async ()=>{
            this.getLimit()
            this.submitting = false
            this.showWaiting = false;
            this.showCastingSuccess = true;
            setTimeout(()=>{
              this.showCastingSuccess = false;
            }, 3000)
            this.amount = ''
            this.usdtContract.getBalance()
            this.usdContract.getBalance()
            Toast(this.$t('success'))
            return [null]
          }, [amount])
        })  
        if(err != null) {
          this.submitting = false
          this.showWaiting = false;
          this.amount = ''
          this.showCastingFailed = true
          setTimeout(()=>{
            this.showCastingFailed = false;
          }, 3000)
          this.getLimit()
          Toast(err)
        }
    },
    async sellUsd() {
      if(this.submitting){
        return;
      }
      if(this.amount == '' || this.amount == 0) {
        Toast(this.$t('enterAmount'))
        return;
      }
      this.submitting = true
      this.showWaiting = true;
      const amount = amount2Wei(this.amount, this.usdContract.decimal)
      
      let [err] = await this.usdContract.approveMethod(this.repurchase, this.usdContract.decimal, this.amount, async ()=>{
        return this.repurchaseContract.estimateMethod('sell', async ()=>{
          this.submitting = false
          this.showWaiting = false;
          this.showCastingSuccess = true;
          setTimeout(()=>{
            this.showCastingSuccess = false;
          }, 3000)
          this.amount = ''
          this.usdContract.getBalance()
          this.usdtContract.getBalance()
          this.getLimit()
          Toast(this.$t('success'))
          return [null]
        }, [amount])
      })  
      if(err != null) {
        this.submitting = false
        this.showWaiting = false;
        this.amount = ''
        this.showCastingFailed = true
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        this.getLimit()
        Toast(err)
      }
    },
    async all() {
      let balance = this.submitType == 'BUY' ?  (this.usdtContract && this.usdtContract.balanceOf || '0.00') : (this.singleAmount || '0.00')
      this.amount = balance
    },
  }
}
</script>
<style scoped lang="scss">
.content_wrap{
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
}
.van-cell__title, .van-cell__value{
  text-align: left;
}
.section_bg{
  padding-left: 48px;
}
.limit_wrap{
  box-shadow: 0px 4px 40px 0px rgba(35, 52, 208, 0.1);
  border: 2px solid #1F1F1F;
  padding: 30px 48px;
  border-radius: 40px;
  background-color: #fff;
  .limit_wrap_title{
    position: absolute;
    border: 2px solid #1F1F1F;
    padding: 5px 18px;
    background-color: #fff;
    border-radius: 14px;
    top: -30px;
    left: 50px;
  }
  &.burn_wrap{
    box-shadow: 0px 4px 40px 0px rgba(35, 52, 208, 0.1);
    border: 2px solid #4C40F7;
    .limit_wrap_title{
      border: 2px solid #4C40F7;
    }
  }
}
.popupContent{
  padding: 30px;
  position: relative;
  .close_position{
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
.tags{
  box-shadow: 0px 4px 40px 0px rgba(35, 52, 208, 0.1);
    border: 2px solid #4C40F7;
  background-color: #fff;
  border-radius: 40px;
  width: fit-content;
  .tag{
    padding: 30px 48px;
    border-radius: 40px;
    &.curr{
      color: #fff;
      background-color: #4C40F7;
    }
  }
}
</style>