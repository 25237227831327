import Vue from 'vue'
import VueI18n from 'vue-i18n'
const messages = {
  zh: {
    // common
    balance: '余额',
    ownBalance: '持有余额',
    stack: '质押',
    comfirm: '确定质押',
    liquidity: '流动性',
    totalSupply: '流通总量',
    used: '余额',
    max: '最大',
    fee: 'Fee',
    swap: '兑换',
    // 个性化
    dashboard: 'Dashboard',
    DASHBOARD: '数据看板',
    swapcoins: '铸造',
    earn: '质押',
    cast: 'Minting',
    stacking: 'Staking',
    rewards: '奖励',
    copy: '复制',
    rewardsTips: '质押LUSD并赚取收益',
    choiceCoin: '选择币种',
    storeAmount: '质押总额', // 质押总量
    totalGusd: 'LUSD 总量',
    totalUsd: 'USD总量',
    yieldReserve: '全网总奖励',
    lusdTotalSupply: 'LUSD 总量',
    myDepositAmount: '我的存款金额',
    swapCoin: '铸造',
    swapCoinLusd: 'LUSD 兑换',
    swapCoinTips: '快速且方便的铸造LUSD',
    swapCoinLusdTips: '快速且方便的兑换LUSD',
    ruleDesc: '规则介绍',
    ruleDesc1: '为防止来自链上的攻击，我们启用了以下规则：当用户进行铸造时，按流动池中代币LITE的价格等比铸造相应的LUSD',
    ruleDesc2: '铸造',
    ruleDesc3: '1) 单次铸造的数量无法超过流动池深度的0.5%',
    ruleDesc4: '2) 每小时铸造数量无法超过流动池深度的1%',
    ruleDesc5: '3) 每日铸造数量无法超过流动池深度的2%',
    ruleDesc6: '赎回',
    ruleDesc7: '当用户进行赎回时，按流动池中代币LITE的价格等比销毁相应的LUSD',
    ruleDesc8: '',
    ruleDesc9: '单次销毁的数量无法超过流动池深度的0.5%；每小时消耗数量无法超过流动池深度的1%',
    singleLimit: '单次限额',
    sellLimit: '卖出额度',
    buyLimit: '剩余额度',
    hourLimit: '每小时限额',
    dayLimit: '每天限额',
    lusdLimit: 'LUSD铸造限额',
    lusdRepurchaseLimit: 'LUSD兑换限额',
    totalReward: '累计奖励',
    waitReceiveReward: '可领取收益',
    reward: '分红奖励',
    receiveReward: '领取',
    receiveRewardLP: '领取奖励',
    myAssets: '我的资产',
    myStore: '我的存款',
    store: '存款',
    unStack: '解押',
    nextTime: '下一次领取时间',
    next: '下一步',
    mode: '模式',
    amount: '数量',
    slippageTolerance: '滑点',
    receive: '收到',
    rewardsToBeDistributed: '本次分配协议收入',
    totalCumulativeReward: '累积领取奖励',
    invitePeople: '邀请',
    myInvitee: '我的邀请人',
    deposit: '质押',
    decompress: '解除质押',
    findInviter: '填入邀请地址',
    findInviterTips: '绑定之前请确保您的邀请人也绑定了邀请人',
    cancel: '退出',
    rewardPool: '待分配协议收入',
    totalFee: '总协议收入',
    choiceStackDeadline: '选择质押期限',
    day: '{number}天',
    lockDay: '锁仓{number}天',
    stackLpAmount: '质押数量',
    illustrateTip1: '* 质押期间，您不能取回本金',
    // illustrateTip2: '* 质押到期后，五天内可以解除质押，超过五天后自动续押一个周期。',
    curr: '活期',
    bonusReward: '更新收益',
    know: '知道了',
    burn: '燃烧',
    mint: '铸造',
    liteBalance: '我的Lite余额',
    lusdBalance: '我的Lusd余额',
    totalDeposit: '我的质押',
    success: '成功',
    select: '请选择一个币种',
    selectSample: '两个币种相同',
    selectLUSD: '请选择LUSD',
    enterAmount: '请输入数量',
    lusdlimitPlaceholder: '超过用户限制',
    hourlimitPlaceholder: '超过小时限制',
    daylimitPlaceholder: '超过日限制',
    enterAddress: '输入地址',
    notLiteMintLimit: '流动池lite不足',
    stakingLP: '质押LP',
    stakingLPTips: '质押LP挖矿',
    countDown: '获取奖励倒计时',
    addStake: '增加质押',
    authorize: '授权',
    more: '更多',
    hidden: '隐藏信息',
    pledgePeriod: '质押周期',
    pledgeTips: '24小时后可以解除质押',
    viewContract: '查看合约',
    prevStakeTime: '上次质押时间',
    get: '获取',
    totalStake: '总质押',
    nounstake: '解押时间未到',
    buy: '买入',
    sell: '卖出',
    lusdMarket: 'LUSD市场',
    upgradeTips: '原来的 LITE 代币按照 1 LITE = 1 LITE（新）的比例换成新的 LITE 代币。兑换时间为一周，一周后开放接收新的LITE代币并建立新的流动性池',
    convertibleBalance: '可兑换余额',
    available: '可解锁',
    upgrade: '升级',
    unlock: '解锁',
    end: '结束',
    unlockTime: '解锁时间',
    endTime: '结束时间'
  },
  en: {
    // common
    balance: 'Balance',
    ownBalance: '持有余额',
    stack: 'Stake',
    comfirm: '确定质押',
    liquidity: '流动性',
    totalSupply: '流通总量',
    used: 'Available',
    max: 'MAX',
    fee: 'Fee',
    swap: '兑换',
    // 个性化
    dashboard: 'Dashboard',
    DASHBOARD: 'DASHBOARD',
    swapcoins: 'swapcoins',
    cast: 'Minting',
    stacking: 'Staking',
    rewards: 'Rewards',
    copy: 'COPY',
    earn: 'EARN',
    rewardsTips: 'Stake LUSD and earn rewards',
    choiceCoin: '选择币种',
    storeAmount: 'TOTAL VALUE LOCKED', // 质押总量
    totalGusd: 'Total LUSD',
    totalUsd: 'Total USD',
    lusdTotalSupply: 'LUSD Total Supply',
    yieldReserve: 'Total Reward',
    myDepositAmount: 'OWN STAKE',
    swapCoin: 'SWAP COINS',
    swapCoinLusd: 'SWAP LUSD',
    swapCoinTips: 'Quick and easy minting LUSD',
    swapCoinLusdTips: 'Quick and easy swaping LUSD',
    // ruleDesc: 'Rules Description',
    totalReward: '累计奖励',
    waitReceiveReward: 'Total Claimable Rewards',
    reward: '分红奖励',
    receiveReward: 'Claim',
    receiveRewardLP: 'Claim',
    myAssets: 'my Asset',
    myStore: '我的存款',
    store: '存款',
    unStack: '解除质押',
    nextTime: 'Next time',
    next: 'Next',
    ruleDesc: 'Rules Description',
    ruleDesc1: 'For example,If the price of Lite is 10 USDT, the user can mint Lite as 10 LUSD through the system.',
    ruleDesc2: 'Mint',
    ruleDesc3: '1) Single minting LUSD can not be greater than 0.5% of the liquidity pool',
    ruleDesc4: '2) Hourly minting LUSD can not be greater than 1% of the liquidity pool',
    ruleDesc5: '3) Daily minting of LUSD can not be greater than 2% of the liquidity pool',
    ruleDesc6: 'Stake',
    ruleDesc7: 'In the LUSD protocol, all users can perform Staking LUSD and thus gain revenue, the revenue is LUSD.',
    ruleDesc8: 'Fee',
    ruleDesc9: 'A fee of 0.3% is charged for each transfer, up to a maximum of 1 LUSD per transfer, with all fees charged going back to StakingLUSD.',
    singleLimit: 'Single limit',
    repurchaseLimit: 'swap limit',
    hourLimit: 'Hourly limit',
    dayLimit: 'Daily limit',
    lusdLimit: 'LUSD limit',
    lusdRepurchaseLimit: 'LUSD swap limit',
    mode: 'Mode',
    amount: 'Amount',
    slippageTolerance: 'Slippage Tolerance',
    receive: 'Receive',
    rewardsToBeDistributed: 'Distributed agreement revenue',
    totalCumulativeReward: 'cumulative',
    invitePeople: 'Invite',
    myInvitee: 'My invitee',
    deposit: 'Deposit',
    decompress: 'Unstake',
    findInviter: 'Fill in the inviter',
    findInviterTips: 'Please check the inviter and make sure it cannot be modified after submission',
    cancel: 'Cancel',
    rewardPool: 'Unallocated agreement revenue',
    totalFee: 'Total agreement revenue',
    choiceStackDeadline: 'Choose a pledge period',
    day: '{number} day',
    lockDay: 'Lock up for {number} days',
    stackLpAmount: 'Number of pledged',
    illustrateTip1: '* During the pledge period, you cannot get your principal back',
    // illustrateTip2: '* After the pledge expires, the pledge can be released within five days, and the pledge will be automatically renewed for a cycle after five days.',
    curr: 'Current',
    bonusReward: 'Update reward',
    know: 'Know',
    burn: 'Burn',
    mint: 'Mint',
    liteBalance: 'my Lite',
    lusdBalance: 'my lusd',
    totalDeposit: 'Total Deposit',
    success: 'Success',
    select: 'Select',
    selectSample: 'Select Sample',
    selectLUSD: 'Select LUSD',
    enterAmount: 'Enter amount',
    lusdlimitPlaceholder: 'user limit',
    hourlimitPlaceholder: 'hour limit',
    daylimitPlaceholder: 'Daily limit',
    enterAddress: 'enter Address',
    notLiteMintLimit: 'Insufficient amount',
    stakingLP: 'Stake Lp',
    stakingLPTips: 'Staking LP tokens to earn.',
    countDown: 'Dividend countdown',
    addStake: 'ADD',
    authorize: 'authorize',
    more: 'more',
    hidden: 'Hidden',
    pledgePeriod: 'Pledge Period',
    pledgeTips: 'The pledge can be released after 24 hours',
    viewContract: 'View contract',
    prevStakeTime: 'Last pledge time',
    get: 'Get',
    totalStake: 'Total Staking',
    nounstake: 'can not unStake',
    buy: 'Buy',
    sell: 'Sell',
    lusdMarket: 'LUSD Market',
    upgradeTips: 'The original LITE tokens are exchanged for new LITE tokens according to the ratio of 1 LITE = 1 LITE (new). The exchange time will last for one week, and after one week open to receive new LITE tokens and establish a new Liquidity pool',
    convertibleBalance: 'Convertible Balance',
    available: 'Available',
    upgrade: 'Upgrade',
    unlock: 'Unlock',
    end: 'ENDED',
    unlockTime: 'Unlock Time',
    endTime: 'End Time'
  },
}
Vue.use(VueI18n);
let lang = window.localStorage.getItem('LANGUAGE') || 'zh'
// const lang = 'zh'
const i18n = new VueI18n({
  locale: lang, // set locale
  silentFallbackWarn: true,
  messages, // set locale messages
})

export default i18n



