<template>
  <div class="pt_48 pb_96">
    <div class="flex_h_between_center section_bg">
      <div class="flex_v_center_start aLeft">
        <div class="fStyle54_5565F9_bold_eina">LITE</div>
        <div class="fStyle54_1F1F1F_bold_eina">UPGRADE</div>
        <div class="flex_h_center_center mt_15">
          <div class="fStyle30_1F1F1F_bold_Eina02-Bold">1 LITE = <span style="color: #5269EC">1 LITE</span></div>
          <div class="tag fStyle20_FFFFFF_w5">NEW</div>
        </div>
      </div>
      <div class="swap_position">
        <w-image-icon imgName="lite_cube"></w-image-icon>
      </div>
    </div>
    <div class="ph_48">
      <w-section class="mt_40" style="padding: 0px; padding-top: 30px; position: relative;">
        <div class="p_relative" style="z-index: 2">
          <div class="ph_48">
            <div class="flex_h_start_center">
              <w-image-icon imgName="lite"></w-image-icon>
              <div class="fStyle54_1F1F1F_bold_eina ml_15">LITE</div>
            </div>
            <div class="fStyle_6B6B6B_regular mt_10" style="text-align: left">
              {{$t('upgradeTips')}}
            </div>
            <div class="grey_line mt_22"></div>
            <div class="flex_h_between_center mt_22">
              <div class="fStyle22_6B6B6B_regular">{{$t('convertibleBalance')}}</div>
              <div class="fStyle22_3E51D2_w6_Eina02-SemiBold">{{liteContract && liteContract.balanceOf || '0.00' | subNumber}}</div>
            </div>
            <div class="mt_77 flex_h_center_center">
              <div class="fStyle22_6B6B6B_regular">{{$t('available')}}</div>
              <div class="tag fStyle20_FFFFFF_w5 ml_15">NEW</div>
            </div>
            <div class="mt_30 fStyle38_3E51D2_bold_eina02">{{lockBalanceOf | subNumber}}</div>
            <div class="mt_65 flex_h_between_center">
              <button class="button" @click="upgradeLite" :class="canUpgrade ? '' : 'button_disable'">{{ $t('upgrade')}}</button>
              <button class="button ml_40" :class="canClaim ? '' : 'button_disable'" @click="unLock">{{$t('unlock')}}</button>
            </div>
            <div class="flex_h_between_center mt_65" v-if="canUpgrade">
              <div class="fStyle38_1F1F1F_bold_eina02">{{$t('endTime')}}</div>
              <div class="fStyle30_1F1F1F_bold_Eina02-Bold">{{upgradeEndTime | timestamp2Time}}</div>
            </div>
            <div class="flex_h_between_center mt_65" v-else>
              <div class="fStyle38_1F1F1F_bold_eina02">{{$t('unlockTime')}}</div>
              <div class="fStyle30_1F1F1F_bold_Eina02-Bold">{{startTime | timestamp2Time}}</div>
            </div>
          </div>
          <div class="mt_22 bottom_wrap fStyle28_ffffff" :class="canUpgrade ? 'white_bottom_wrap' : ''">
            {{ $t('end')}}
          </div>
        </div>
        <div class="section_inner1"></div>
        <div class="section_inner2"></div>
      </w-section>
    </div>
    <loading :show="showCastingFailed" @close="showCastingFailed=false">Failed</loading>
    <loading-success :show="showCastingSuccess" @close="showCastingSuccess=false">Success</loading-success>
    <loading-waiting :show="showWaiting" @close="showWaiting=false">Please be patient, the process takes about 2 minutes
    </loading-waiting>
  </div>
</template>
<script>
import WSection from '@/components/WSection'
import {wei2Amount } from '@/utils/ethersUtils/helper/number'
import {Toast} from 'vant'
import Loading from '@/components/Loading'
import LoadingSuccess from '@/components/LoadingSuccess'
import LoadingWaiting from '@/components/LoadingWaiting'
import ContractUtils from '@/utils/ethersUtils/contractUtils'
import {NORMAL_ABI, LITEUPGRADE_ABI} from '@/common/abi'
import EthersUtils from '@/utils/ethersUtils/index'
export default {
  data() {
    return {
      provider: null,
      amount: '',
      submitting: false,
      liteContract: null,
      upgradeContract: null,
      lite: process.env.VUE_APP_LITE,
      upgrade: process.env.VUE_APP_LITE_UPGRADE,
      showWaiting: false,
      showCastingFailed: false,
      showCastingSuccess: false,
      upgradeEndTime: '',
      startTime: 0,
      lockBalanceOf: 0,
      upgradeStartTime: 0
    }
  },
  computed: {
    canClaim() {
      if(this.startTime == 0) {
        return false
      }
      if(this.lockBalanceOf == 0) {
        return false
      }
      let nowTimeStr = Date.now().toString().substring(0, 10);
      if(Number(this.startTime) < Number(nowTimeStr)) {
        return true
      } else {
        return false
      }
    },
    canUpgrade() {
      let nowTimeStr = Date.now().toString().substring(0, 10);
      if(this.upgradeEndTime == 0) {
        return false;
      }
      if(this.upgradeStartTime == 0) {
        return false;
      }
      if(Number(this.upgradeEndTime) > Number(nowTimeStr) && Number(this.upgradeStartTime) < Number(nowTimeStr)){
        return true
      } else {
        return false
      }
    }
  },
  components: {WSection, Loading, LoadingSuccess, LoadingWaiting},
  async mounted() {
    const base =  await new EthersUtils()
    this.provider = base;
    this.liteContract = await new ContractUtils(this.lite, NORMAL_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
    this.upgradeContract = await new ContractUtils(this.upgrade, LITEUPGRADE_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
    this.init()
  },
  methods: {
    async init() {
      this.upgradeContract.lockBalanceOf
      const calls = [
        this.upgradeContract.batchCall.lockBalanceOf(this.provider.address),
        this.upgradeContract.batchCall.startUnlockTime(),
        this.upgradeContract.batchCall.upgradeEndTime(),
        this.upgradeContract.batchCall.upgradeStartTime()
      ]
      let [lockBalanceOf, startUnlockTime, upgradeEndTime, upgradeStartTime] = await this.provider.all(calls)
      this.lockBalanceOf = wei2Amount(lockBalanceOf, this.liteContract.decimal)
      this.startTime = wei2Amount(startUnlockTime, 0)
      this.upgradeEndTime = wei2Amount(upgradeEndTime, 0)
      this.upgradeStartTime = wei2Amount(upgradeStartTime, 0)
    },
    async upgradeLite() {
      if(this.submitting){
        return;
      }
      if(!this.canUpgrade) {
        return;
      }
      this.submitting = true
      this.showWaiting = true;
        let [err] = await this.liteContract.approveMethod(this.upgrade, this.liteContract.decimal, this.liteContract.balanceOf || '0', async ()=>{
          return this.upgradeContract.estimateMethod('upgrade', async ()=>{
            this.liteContract.getBalance();
            this.submitting = false
            this.showWaiting = false;
            this.showCastingSuccess = true;
            this.init();
            setTimeout(()=>{
              this.showCastingSuccess = false;
            }, 3000)
            Toast(this.$t('success'))
            return [null]
          })
        })  
        if(err != null) {
          this.submitting = false
          this.showWaiting = false;
          this.amount = ''
          this.showCastingFailed = true
          setTimeout(()=>{
            this.showCastingFailed = false;
          }, 3000)
          this.getLimit()
          Toast(err)
        }
    },
    async unLock() {
      if(this.submitting){
        return;
      }
      this.submitting = true
      this.showWaiting = true;
      
      let [err] = await this.upgradeContract.estimateMethod('unlock', async ()=>{
          this.liteContract.getBalance();
          this.submitting = false
          this.showWaiting = false;
          this.showCastingSuccess = true;
          this.init();
          setTimeout(()=>{
            this.showCastingSuccess = false;
          }, 3000)
          Toast(this.$t('success'))
          return [null]
        })
      if(err != null) {
        this.submitting = false
        this.showWaiting = false;
        this.amount = ''
        this.showCastingFailed = true
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        this.getLimit()
        Toast(err)
      }
    },
  }
}
</script>
<style scoped lang="scss">
.grey_line{
  width: 100%;
  height: 1px;
  background-color: #6B6B6B;
}
.section_bg{
  padding-left: 48px;
}
.bottom_wrap{
  padding: 20px 0 20px 0;
  border-radius: 0 0 40px 40px;
  background: #3E51D2;
  &.white_bottom_wrap{
    background: #fff;
  }
}
.section_inner1{
  position: absolute;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
  // width: 400px;
  border-radius: 40px;
  height: 80px;
  bottom: -20px;
  background: #214398;
  z-index: 1;
}
.section_inner2{
  position: absolute;
  margin-left: 40px;
  margin-right: 40px;
  width: calc(100% - 80px);
  // width: 400px;
  border-radius: 40px;
  height: 80px;
  bottom: -40px;
  background: #02206A;
  z-index: 0;
}
.tag{
    background-color: #5168EB;
    padding: 2px 15px;
    border-radius: 40px;
    margin-left: 8px;
    &.curr{
      color: #fff;
      background-color: #4C40F7;
    }
  }
</style>