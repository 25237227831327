import { ethers } from 'ethers'
import Big from 'big.js'
import { Provider } from "ethers-multicall";

// import { Toast } from 'vant';
const MIN_GASPRICE = 3
class EthersUtils {
  provider;
  ethcallProvider;
  signer;
  address = '';
  gasPrice = MIN_GASPRICE
  constructor() {
    if (typeof (window).ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider((window).ethereum)
      const signer = provider.getSigner()
      this.provider = provider
      this.signer = signer
      this.ethcallProvider = new Provider(this.provider);
      this.ethcallProvider.init();

      const getAddr = (async () => {
        if (window.ethereum.isMetaMask) {
          await (window).ethereum.send('eth_requestAccounts')
        }
        const address = await this.getAddress()
        this.address = address
        let _gasPrice = await this.getGasPrice()
        // if (_gasPrice > MIN_GASPRICE)
        this.gasPrice = _gasPrice;
        delete this.then
        return this
      })()
      this.then = getAddr.then.bind(getAddr)
    }
  }

  // eventName: accountsChanged
  addEventListener(eventName, callback) {
    (window).ethereum.on(eventName, callback)
  }

  async getAddress() {
    return await this.signer.getAddress().then((res) => res).catch((error) => {
      console.log('catch=====', error)
    })
  }

  async getGasPrice() {
    return this.calcAmount(await this.signer.getGasPrice(), 9)
  }

  async getChainId() {
    const network = await this.provider.getNetwork()
    return network.chainId
  }

  async all(calls) {
    const result = await this.ethcallProvider.all(calls);
    return result;
  }

  calcAmount(num, decimal) {
    return Big(num).div(Big(ethers.BigNumber.from(10).pow(decimal))).toFixed()
  }
}

export default EthersUtils
