<template>
  <div class="pt_48 p_relative">
    <div class="flex_h_between_center section_bg">
      <div class="flex_v_center_start">
        <div class="upercase fStyle54_1F1F1F_bold_eina">{{$t('earn')}}</div>
        <div class="mt_27 fStyle28_4E4E4E_w6_einasemibold aLeft">{{$t('stakingLPTips')}}</div>
        <div class="flex_v_center_start mt_22">
          <div class="fStyle20_999999" v-if="bindAddress != ''">{{$t('myInvitee')}} </div>
          <div class="fStyle28_4E4E4E_w6" v-if="bindAddress != ''">{{bindAddress | subAddress(7)}}</div>
          <div class="flex_h_center_center" @click="showInvite=true"  v-else>
            <div class="fStyle28_A39DFF_w6_einasemibold">{{$t('invitePeople')}}</div>
            <div class="ml_7"><w-image-icon imgName="arrow_right"></w-image-icon></div> 
          </div>
        </div>
      </div>
      <div class="swap_position">
        <w-image-icon imgName="stakingLP_bg"></w-image-icon>
      </div>
    </div>
    <!-- {{lastBounsAt | timestamp2Time}} -->
      <div class="countdown_wrap">
        <div class="aLeft flex1">
          <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold uppercase" style="color: #3E51D2">{{$t('totalFee')}}</div>
          <div class="fStyle50_1F1F1F_bold mt_10">{{totalFee | subNumber}}<span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
          <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold uppercase mt_30" style="color: #3E51D2">{{$t('rewardPool')}}</div>
          <div class="fStyle50_1F1F1F_bold mt_10">{{rewardPool | subNumber}}<span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
          <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold mt_30 uppercase">{{$t('rewardsToBeDistributed')}}</div>
          <div class="fStyle50_1F1F1F_bold mt_10">{{bonusReward | subNumber}}<span class="fStyle26_1F1F1F_bold_Eina02-SemiBold">LUSD</span></div>
        </div>
        <div class="fStyle24_AFAFAF_w6_Eina02-SemiBold mt_30">{{$t('countDown')}}</div>
        <van-count-down
            :time="lastBounsDuration"
            class="mt_10"
        >
            <template #default="timeData">
                <span class="block fStyle32_F3F5F9_bold">{{ String(timeData.hours).length == 2 ?  String(timeData.hours)[0] : '0'}}</span>
                <span class="block fStyle32_F3F5F9_bold ml_10">{{ String(timeData.hours).length == 2 ?  String(timeData.hours)[1] : String(timeData.hours)[0] }}</span>
                <span class="colon">:</span>
                <span class="block fStyle32_F3F5F9_bold">{{ String(timeData.minutes).length == 2 ? String(timeData.minutes)[0] : '0' }}</span>
                <span class="block fStyle32_F3F5F9_bold ml_10">{{ String(timeData.minutes).length == 2 ? String(timeData.minutes)[1] : String(timeData.minutes)[0] }}</span>
                <span class="colon">:</span>
                <span class="block fStyle32_F3F5F9_bold">{{ String(timeData.seconds).length == 2 ? String(timeData.seconds)[0] : 0 }}</span>
                <span class="block fStyle32_F3F5F9_bold ml_10">{{ String(timeData.seconds).length == 2 ? String(timeData.seconds)[1] : String(timeData.seconds)[0] }}</span>
            </template>
        </van-count-down>
    </div>
      <div class="ph_48">
        <w-lp-item class="mt_40" v-for="(item, index) in poolList" :key="item.id" :class="index == 0 ? 'mt_40' : ''" :item="item" :type="item.token1" @on-reward="receiveReward(item.id, item.pairAddress)" @on-unstack="showUnPopup" @on-stack="showPopup"></w-lp-item>
      </div>
     
     <van-popup v-model="showInvite" style="width: 90%" round>
       <div class="popup_content">
         <div class="flex_h_center_center"><w-image-icon imgName="invite"></w-image-icon></div>
         <div class="fStyle32_4E4E4E_bold mt_65">{{$t('findInviter')}}</div>
         <div class="mt_10 fStyle22_C1C1CA">{{$t('findInviterTips')}}</div>
         <input type="text" class="input mt_22" v-model="inviteAddress" />
         <button class="button mt_30" @click="bindInviter">{{$t('next')}}</button>
         <button class="text_button" @click="showInvite=false">{{$t('cancel')}}</button>
       </div>
     </van-popup>
    <van-popup v-model="showPledge" style="width: 90%" round>
      <div class="popup_stack_content">
        <div class="fStyle32_4E4E4E_bold">{{$t('stack')}}</div>
        <div class="close_position" @click="showPledge=false">
          <w-image-icon imgName="close"></w-image-icon>
        </div>
        <div class="flex_h_between_center mt_33">
          <div class="fStyle24_292921">{{$t('pledgePeriod')}}</div>
        </div>
        <div class="mt_30 fStyle22_A3AFFF_w6_Eina02-SemiBold aLeft">{{$t('pledgeTips')}}</div>
        <div class="mt_50 flex_h_between_center">
            <div class="fStyle24_292921" v-if="currPair && currPair.singleStake">{{currPair && currPair.token0}}</div>
            <div class="fStyle24_292921" v-else>{{currPair && currPair.token0}}-{{currPair && currPair.token1}} LP</div>
            <div class="fStyle24_292921">
              {{currPair && currPair.pairContract.balanceOf || '0' | subNumber(12)}}&nbsp;&nbsp;{{$t('balance')}}
          </div>
        </div>
        <div class="mt_22 input_wrap border_input">
          <input type="text" v-model="storeAmount" class="input" />
          <div class="text_postion fStyle20_2334D0_w5" @click="storeAmount = currPair && currPair.pairContract.balanceOf || '0'">{{$t('max')}}</div>
        </div>
        <button class="button blue_button mt_77 large_button" @click="stake">{{$t('next')}}</button>
      </div>
    </van-popup>
    <van-popup v-model="showUnPledge" style="width: 90%" round>
      <div class="popup_stack_content">
        <div class="fStyle32_4E4E4E_bold">{{$t('decompress')}}</div>
        <div class="close_position" @click="showUnPledge=false">
          <w-image-icon imgName="close"></w-image-icon>
        </div>
        <div class="mt_50 flex_h_between_center">
            <div class="fStyle24_292921" v-if="currPair && currPair.singleStake">{{currPair && currPair.token0}} </div>
            <div class="fStyle24_292921" v-else>{{currPair && currPair.token0}}-{{currPair && currPair.token1}} LP</div>
            <div class="fStyle24_292921">
              {{currPair && currPair.stakeAmount || '0' | subNumber(12)}}&nbsp;&nbsp; {{$t('balance')}}
          </div>
        </div>
        <div class="mt_22 input_wrap border_input">
          <input type="text" v-model="unStoreAmount" class="input" />
          <div class="text_postion fStyle20_2334D0_w5" @click="unStoreAmount = currPair && currPair.stakeAmount || '0'">{{$t('max')}}</div>
        </div>
        <button class="button blue_button mt_77 large_button" @click="unStake">{{$t('next')}}</button>
      </div>
    </van-popup>
     <loading :show="showCastingFailed" @close="showCastingFailed=false">Failed</loading>
    <loading-success :show="showCastingSuccess" @close="showCastingSuccess=false">{{$t('success')}}</loading-success>
    <loading-waiting :show="showWaiting" @close="showWaiting=false">Please be patient, the process takes about 2 minutes</loading-waiting>
    <div class="modal_top flex_h_center_center" v-if="false">
        <div class="modal_icon">
            <w-image-icon imgName="lite"></w-image-icon>
        </div>
        <div class="flex1 aLeft ml_40">
            <div class="fStyle32_4E4E4E_bold_Eina02-SemiBold">Authorization <br />successful</div>
            <div class="mt_22 fStyle24_4E4E4E">Go pledge LP</div>
        </div>
    </div>
     <div class="modal_top flex_h_center_center" v-if="false">
        <div class="modal_icon red_bg">
            <w-image-icon imgName="lite"></w-image-icon>
        </div>
        <div class="flex1 aLeft ml_40">
            <div class="fStyle32_4E4E4E_bold_Eina02-SemiBold">Fail</div>
            <div class="mt_22 fStyle24_4E4E4E">Privilege Grant Failed</div>
        </div>
    </div>
  </div>
</template>
<script>
import {store} from '@/store'
import WLpItem from '@/components/WLPItem'
import { amount2Wei, wei2Amount } from '@/utils/ethersUtils/helper/number'
import {Toast} from 'vant'
import { USDR_ABI, PAIR_ABI, DEPOSIT_ABI, USD_ABI } from '@/common/abi'
import ContractUtils from '@/utils/ethersUtils/contractUtils'
import Loading from '@/components/Loading'
import LoadingSuccess from '@/components/LoadingSuccess'
import LoadingWaiting from '@/components/LoadingWaiting'
import { Decimal } from '@/utils/utils'
import { ethers } from 'ethers'
import { get } from '@/utils/request/http'
// TODO
// const CYCLE = [0, 10, 20, 30]
const CYCLE = [0, 500, 1000, 1500]
export default {
  data() {
    return {
      contract: null,
      storeAmount: '',
      totalReward: 0, 
      waitReceiveReward: 0,
      totalStack: 0,
      totalSupply: 0,
      bonusReward: 0, 
      stakedOf: 0,
      submitting: false,
      unStoreAmount: '',
      rewardTo: '', 
      stakeAt: 0, 
      unstakeDuration: 86400, 
      showInvite: false,
      showPledge: false,
      showUnPledge: false,
      durationIndex: 0,
      cycles: CYCLE,
      // cycleSec: 86400, // TODO: cycle sections
      cycleSec: 1,
      usd: process.env.VUE_APP_USD,
      deposit: process.env.VUE_APP_USD_DEPOSIT,
      usdStackLP: process.env.VUE_APP_USD_STAKELP,
      lite: process.env.VUE_APP_LITE,
      inviteAddress: '',
      bindAddress: '',
      showWaiting: false,
      showCastingFailed: false,
      showCastingSuccess: false,
      rewardPool: 0,
      bounsDuration: 0,
      lastBounsRate: 0,
      totalStaked: '', 
      poolList: [],
      stakePools: [],
      totalPoint: 0,
      totalFee: 0,
      currPair: null,
    }
  },
  components: {WLpItem, Loading, LoadingSuccess, LoadingWaiting},
  computed: {
    provider(){
      return store.provider
    },
    liteContract() {
      return store.liteContract
    },
    usdContract() {
      return store.usdContract
    },
    usdStakeLPContract() {
      return store.usdStakeLPContract
    },
    unStakeAt() {
      if(this.stakeAt == 0) {
        return ''
      }
      return Number(this.stakeAt) + Number(this.unstakeDuration)
    },
    lastBounsAt() {
      if(this.bounsDuration == 0 || this.lastBounsRate == 0) {
        return 0
      }
      return Decimal.add(Decimal.mul(this.bounsDuration, this.lastBounsRate), this.bounsDuration).toFixed()
    },
    lastBounsDuration() {
      if(this.bounsDuration == 0 || this.lastBounsRate == 0) {
        return 0
      }
      let lastBounsAt =  Decimal.add(Decimal.mul(this.bounsDuration, this.lastBounsRate), this.bounsDuration).toFixed()
      let nowTimeStr =  Date.now().toString().substring(0, 10);
      if(Number(nowTimeStr) < Number(lastBounsAt)){
        return (Number(lastBounsAt) - Number(nowTimeStr)) * 1000
      } else {
        return 0;
      }
    },
    unstakeAble() {
      if(this.unStoreAmount == '' || this.unStoreAmount == 0){
        return false
      }
      let nowTimeStr = Date.now().toString().substring(0, 10);
      if(Number(nowTimeStr) < Number(this.unStakeAt)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    provider() {
      if(this.contract == null){
        this.initContract();
      }
    },
    usdStakeLPContract() {
      this.init()
    }
  },
  mounted() {
    if(this.provider != null){
      this.initContract();
    }
  },
  methods: {
    async initContract() {
      if(this.usdStakeLPContract != null){
        this.init()
      }
    },
    async init() {
      await get("stake_pool.json").then(res => {
        this.stakePools = res;
      })
      await this.setPools()
      await this.poolInit()
    },
    async setPools() {
      let poolsInfo = this.stakePools;
      for(let i = 0; i < poolsInfo.length; i++){
        const pairAddress = poolsInfo[i].address;
        let point = poolsInfo[i].point
        if(point == 0){
          continue;
        }
        let exchangeName = poolsInfo[i].exchange;
        let token0 = poolsInfo[i].token0;
        let token1 = poolsInfo[i].token1;

        this.poolList.push({
          id: i,
          apy: "1000",
          lastDepositAt: 0,//stakeAt,
          pairAddress,
          totalStakeAmount: 0,//totalStaked,
          totalStakeUSDT: 0,
          stakeAmount: 0,//stakedOf,
          stakeUSDT: 0,//stakedOf,
          reward: 0,//waitReceiveReward,
          token0: token0.toUpperCase(),
          token1: token1.toUpperCase(),
          // pairContract,
          singleStake: !poolsInfo[i].is_lp,
          duration: this.unstakeDuration,
          totalReward: 0,//userReward,
          rate: 100,//pointRate,
          exchangeName: exchangeName
        })
      }
    },
    async poolInit() {
      let depositContract = await new ContractUtils(this.deposit, DEPOSIT_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
      let usdContract = await new ContractUtils(this.usd, USD_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
      let calls = [
        this.usdStakeLPContract.batchCall.totalAllocPoint(),
        depositContract.batchCall.getFee(),
        this.usdStakeLPContract.batchCall.bounsDuration(),
        usdContract.batchCall.inviter(this.provider.address),
        this.usdStakeLPContract.batchCall.getPendingReward(),
        this.usdStakeLPContract.batchCall.unstakeDuration(),
        this.usdStakeLPContract.batchCall.lastBounsEpoch(),
        depositContract.batchCall.totalFees()
      ]
      let [totalPoint, rewardPool, bounsDuration, address, bonusReward, unstakeDuration, lastBounsEpoch, totalFee] = await this.provider.all(calls);
      this.totalPoint = wei2Amount(totalPoint, 0)
      this.rewardPool = wei2Amount(rewardPool, this.usdContract.decimal);
      this.bounsDuration = wei2Amount(bounsDuration, 0)
      this.bindAddress = address == ethers.constants.AddressZero ? '' : address;
      this.bonusReward = wei2Amount(bonusReward, this.usdContract.decimal)
      this.lastBounsRate = wei2Amount(lastBounsEpoch, 0)
      this.unstakeDuration = wei2Amount(unstakeDuration, 0)
      this.totalFee = wei2Amount(totalFee, this.usdContract.decimal)

      let poolsInfo = this.stakePools;
      for(let i = 0; i < poolsInfo.length; i++){
        let point = poolsInfo[i].point
        if(point == 0){
          continue;
        }
        await this.getPoolInfo(i)
      }
    },
    async getPoolInfo(i) {
      let poolsInfo = this.stakePools;
      const pairAddress = poolsInfo[i].address;
      let point = poolsInfo[i].point
      if(point == 0){
        return;
      }

      let pairContract = await new ContractUtils(pairAddress, PAIR_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider)
      let callInfos = [
        this.usdStakeLPContract.batchCall.poolInfo(pairAddress),
        this.usdStakeLPContract.batchCall.userInfo(pairAddress, this.provider.address),
        this.usdStakeLPContract.batchCall.rewardAmount(this.provider.address, pairAddress),
        this.usdContract.batchCall.balanceOf(pairAddress),
        pairContract.batchCall.totalSupply(),
      ]

      let [poolInfo, userInfo, waitReceiveReward, pairUsd, lpTotal] = await this.provider.all(callInfos);
      let stakeAt = 0;
      if (userInfo.lastDepositAt && userInfo.lastDepositAt.toNumber() > 0) {
        stakeAt = wei2Amount(userInfo.lastDepositAt, 0);
      }
      let stakedOf = wei2Amount(userInfo.stakedOf, this.usdContract.decimal);
      waitReceiveReward = wei2Amount(waitReceiveReward, this.usdContract.decimal);

      let totalStaked = wei2Amount(poolInfo.totalStaked, this.usdContract.decimal);
      let userReward = wei2Amount(userInfo.userReward, this.usdContract.decimal);
      let pointRate = Decimal.mul(Decimal.div(point, this.totalPoint), 100).toNumber();

      let lpPrice = 1;
      if(!this.poolList[i].singleStake){
          lpPrice = Decimal.div(pairUsd.mul(2), lpTotal).toString();
      }
      let apy = this.getAPY(totalStaked, point, 1)
      // let apy1 = this.getAPY(totalStaked, point, lpPrice)

      // console.log("【",this.poolList[i].token0,this.poolList[i].token1, "】Rate ", pointRate,"X APY ", apy, "%,", " LP PRICE: ", lpPrice, ",USDT APY ", apy1, "%,");
      // console.log("totalStaked LP", totalStaked, ",totalStaked LP USDT", totalStaked*lpPrice);
      // console.log("stake LP", stakedOf, ",stake LP USDT", stakedOf*lpPrice, "\n");

      this.poolList[i].apy = apy;
      this.poolList[i].lastDepositAt = stakeAt;
      this.poolList[i].totalStakeAmount = totalStaked;
      this.poolList[i].stakeAmount = stakedOf;
      this.poolList[i].totalStakeUSDT = totalStaked*lpPrice;
      this.poolList[i].stakeUSDT = stakedOf*lpPrice;
      this.poolList[i].reward = waitReceiveReward;
      this.poolList[i].totalReward = userReward;
      this.poolList[i].rate = pointRate;
      this.poolList[i].pairContract = pairContract;
    },
    getAPY(totalStaked, point, lpPrice) {
      if(totalStaked == 0) {
        return 1000
      }
      // if(rewardPool == 0) {
      //   return 0;
      // }
      if(point == 0) {
        return 0
      }
      let pointRate = Decimal.div(point, this.totalPoint)
      // dayliy rate
      // const dayRate = Decimal.div(Decimal.mul(Decimal.div(this.rewardPool, 25),pointRate), totalStaked * lpPrice)
      const dayRate = Decimal.div(Decimal.mul(this.bonusReward,pointRate), totalStaked * lpPrice)
      
      let apy = Decimal.mul(Decimal.sub(Decimal.add(1, dayRate).pow(356), 1), 100)
      if(apy.gt(99999999999)){
        return 99999999999
      } else{
        return apy.toFixed(2)
      }
    },
    async bindInviter() {
      if(this.submitting){
        return;
      }
      if(this.inviteAddress == '') {
        Toast('enter Address');
        return;
      }
      this.submitting = true
      this.showWaiting = true;
      this.showInvite = false;
      const usdContract = await new ContractUtils(this.usd, USDR_ABI, this.provider.signer, this.provider.address, this.provider.gasPrice, this.provider.provider, false)
      let [err] = await usdContract.estimateMethod('setInviter', ()=>{
          this.submitting = false;
        this.showWaiting = false
        this.showCastingSuccess = true
        setTimeout(()=>{
            this.showCastingSuccess = false;
        }, 3000)
          this.init();
        Toast(this.$t('success'))
        return [null]
      }, [this.inviteAddress]);
      if(err != null) {
        this.submitting = false
        this.showWaiting = false
        this.showCastingFailed = true;
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        Toast(err);
      }
    },
    // receive reward
    async receiveReward(id, pairAddress) {
      if(this.submitting){
        return;
      }
      this.submitting = true
      this.showWaiting = true;
      let [err] = await this.usdStakeLPContract.estimateMethod('takeReward',async ()=>{
          this.submitting = false;
          this.showWaiting = false;
          this.showCastingSuccess = true;
        setTimeout(()=>{
            this.showCastingSuccess = false;
        }, 3000);
        await this.getPoolInfo(id);
        await this.usdContract.getBalance();
        Toast(this.$t('success'));
        return [null];
      }, [pairAddress]);
      if(err != null) {
        this.submitting = false;
        this.showWaiting = false;
        this.showCastingFailed = true;
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        Toast(err);
      }
    },
    async stake() {
      if(this.submitting){
        return;
      }
      if(this.storeAmount == '' || this.storeAmount == 0){
        Toast(this.$t('enterAmount'))
        return;
      }

      let amount = amount2Wei(this.storeAmount, this.currPair.pairContract.decimal)
      this.submitting = true
      this.showPledge = false
      this.showWaiting = true
      // const duration = this.cycles[this.durationIndex] * this.cycleSec
      // TODO: current cycles is current
      const duration = 0
      let [err] = await this.currPair.pairContract.approveMethod(this.usdStackLP, this.currPair.pairContract.decimal, this.storeAmount, async ()=>{
        return this.usdStakeLPContract.estimateMethod('stake', async ()=>{
          this.submitting = false
              this.showWaiting = false;
          this.storeAmount = ''
          this.showPledge = false
          this.showWaiting = false
          this.showCastingSuccess = true
          setTimeout(()=>{
            this.showCastingSuccess = false;
          }, 3000);
          await this.getPoolInfo(this.currPair.id)
          await this.usdContract.getBalance()
          Toast(this.$t('success'))
          return [null]
        }, [this.currPair.pairAddress,amount, duration])
      })  
      if(err != null) {
        this.submitting = false
        this.showWaiting = false;
        this.showCastingFailed = true
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        this.storeAmount = ''
        Toast(err)
      }
    },
    async unStake() {
      if(this.submitting){
        return;
      }
      if(this.unStoreAmount == '' || this.unStoreAmount == 0){
        Toast(this.$t('enterAmount'))
        return;
      }
      let amount = amount2Wei(this.unStoreAmount, this.currPair.pairContract.decimal)
      this.submitting = true
      this.showWaiting = true
      let [err] = await this.usdStakeLPContract.estimateMethod('unstake',async ()=>{
        this.unStoreAmount = ''
          this.submitting = false;
          this.showWaiting = false;
          this.showCastingSuccess = true;
        this.showUnPledge = false
        setTimeout(()=>{
            this.showCastingSuccess = false;
        }, 3000)
        await this.getPoolInfo(this.currPair.id)
        await this.usdContract.getBalance();
        Toast(this.$t('success'))
        return [null]
      },[this.currPair.pairAddress ,amount]);
      if(err != null) {
        this.unStoreAmount = ''
        this.submitting = false
        this.showWaiting = false
        this.showCastingFailed = true
        this.showUnPledge = false
        setTimeout(()=>{
          this.showCastingFailed = false;
        }, 3000)
        Toast(err);
      }
    },
    showPopup(info) {
      this.currPair = info
      this.showPledge = true
    },
    showUnPopup(info) {
      this.currPair = info
      this.showUnPledge = true
    },
  }
}
</script>
<style lang="scss" scoped>
.section_bg{
  padding-left: 48px;
  position: relative;
  height: 260px;
    .swap_position{
    position: absolute;
    right: 0;
    right: 0;
  }
}
  .countdown_wrap{
  padding: 56px;
  background-color: #fff;
      box-shadow: 0px 4px 40px 0px rgba(35,52,208,0.1000);
  text-align: left;
  border-radius: 40px;
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 50px;
}
  .box_wrap{
  background-color: #fff;
  border-radius: 40px;
  padding: 40px 48px;
  box-sizing: border-box;
  margin-top: 40px;
}
// .h_line{
//     width: 100%;
//     height: 2px;
//     background-color: #D8D8D8;
//     margin-top: 20px;
//     margin-bottom: 40px;
// }
.colon {
  display: inline-block;
  margin: 0 12px;
    color: #A4ABE1;
}
.block {
  display: inline-block;
  text-align: center;
    background-color: #A4ABE1;
  padding: 16px;
  border-radius: 8px;
}
  .modal_top{
  position: absolute;
  top: 48px;
  left: 0px;
  margin-left: 48px;
  margin-right: 48px;
  width: calc(100% - 96px);
  background-color: #fff;
  border-radius: 40px;
      .modal_icon{
    padding: 65px;
    border-radius: 40px;
          background-color: #3E51D2;
          &.red_bg{
              background-color: #FF6E6E;
    }
  }
}
 .update_button{
  width: 186px;
  background: #2334D0;
  height: 300px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  .btn_hr{
    width: 100%;
    height: 1px;
    background: #8C9BFF;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.claim_button{
  width: 186px;
  background: #1F1F1F;
  height: 240px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding-left: 25px;
  // padding-right: 25px;
  // box-sizing: border-box;
}
.bg_wrap{
  position: relative;
  overflow: hidden;
  .bg_position{
    position: absolute;
    bottom: 0;
    right: -30px;
  }
}
.popup_content{
  padding: 85px 48px 48px 48px;
  .text_button{
    margin-top: 30px;
    height: 98px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #C1C1CA;
    font-size: 28px;
  }
}
.popup_stack_content{
  background:#fff;
  border-radius: 20px;
  padding: 44px 40px;
  position: relative;
  .close_position{
    position: absolute;
    right: 40px;
    top: 44px;
  }
  .input_wrap{
    position: relative;
    background-color: #fff;
    border: 2px solid #2334D0;
    overflow: hidden;
    .input{
      height: 102px;
      border-radius: 20px;
      width: 100%;
      box-sizing: border-box;
      font-size: 32px;
      color: #090A1A;
      font-weight: 300;
      padding-left: 32px;
      padding-right: 120px;
      font-family: 'DinBold';
      outline: none;
      border: none;
      background: #fff;

    }
    .text_postion{
      position: absolute;
      right: 32px;
      top: 35px;
    }
  }
  .button_dura{
    outline: none;
    border: none;
    background-color: #F0F1F5;
    border-radius: 20px;
    padding: 15px 30px;
    color: #16182D;
    font-size: 26px;
    width: 150px;
    margin-top: 22px;
    flex-shrink: 1;
    &.curr_button{
      background-color: #2334D0;
      color: #fff;
    }
  }
}
</style>