<template>
  <van-popup v-model="show" round @click-overlay="close" :close-on-click-overlay="false">
    <div class="loading_wrap">
      <div class="flex_h_end_center" @click="close">
        <w-image-icon imgName="close"></w-image-icon>
      </div>
      <lottie :options="defaultOptions" v-on:animCreated="handleAnimation($event)" id="animate_wrap" v-if="load"/>
      <div class="fStyle32_4E4E4E_bold"><slot></slot></div>
      
    </div>
  </van-popup>
</template>

<script>
import lottie from 'vue-lottie'
import * as animationData from '@/assets/loading/loading.json'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    label: String
  },
  components: {
    lottie
  },
  mounted() {
    setTimeout(()=>{
      this.load = true
    }, 500)
  },
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
        loop:true
      },
      anim: null,
      load: false,
    }
  },
  methods: {
    handleAnimation(anim){
      this.anim = anim 
      this.anim.play()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.loading_wrap{
  padding: 40px 40px 70px 40px;
}
.animation_wrap{
  border-radius: 15px;
  width: 30%;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}
</style>